/* eslint-disable import/prefer-default-export */
import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';
import { LicenseGroupData, PublicId, User } from 'app/store/types';

// MOCK DATA ON/OFF TOGGLES
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has('noMock')) {
	localStorage.removeItem('mock');
	localStorage.removeItem('mockUser');
	localStorage.removeItem('mockExpired');
	localStorage.removeItem('mockPlan');
	localStorage.removeItem('mockOrderType');
	localStorage.removeItem('mockRegion');
	localStorage.removeItem('mockVault');
	localStorage.removeItem('mockAppManged');
	localStorage.removeItem('mockLogs');
	localStorage.removeItem('noComingSoonWrapper');
	localStorage.removeItem('enableCheckout');
}

if (urlParams.has('mock')) {
	localStorage.setItem('mock', 'true');
}
if (urlParams.has('mockUser')) {
	localStorage.setItem('mockUser', 'true');
}
if (urlParams.has('mockExpired')) {
	localStorage.setItem('mockExpired', urlParams.get('mockExpired') || '2020-12-12T23:59:59.000Z');
}
if (Object.values(PublicId).includes(urlParams.get('mockPlan') as PublicId)) {
	localStorage.setItem('mockPlan', urlParams.get('mockPlan') as PublicId);
}
if (typeof urlParams.get('mockOrderType') === 'string') {
	localStorage.setItem('mockOrderType', urlParams.get('mockOrderType') as LicenseGroupData['orderType']);
}
if (typeof urlParams.get('mockRegion') === 'string') {
	localStorage.setItem('mockRegion', urlParams.get('mockRegion') as string);
}
if (urlParams.has('mockVault')) {
	localStorage.setItem('mockVault', 'true');
}
if (urlParams.has('mockAppManaged')) {
	localStorage.setItem('mockAppManaged', 'true');
}
if (urlParams.has('noVault')) {
	localStorage.removeItem('mockVault');
}
if (urlParams.has('mockLogs')) {
	localStorage.setItem('mockLogs', 'true');
}
if (urlParams.has('noComingSoonWrapper')) {
	localStorage.setItem('noComingSoonWrapper', 'true');
}
if (urlParams.has('enableCheckout')) {
	localStorage.setItem('enableCheckout', 'true');
}

// HACK-ish::add option for Product to edit site content easily
if (urlParams.has('designMode')) {
	document.designMode = 'on';
	document.body.setAttribute('spellcheck', 'false');
}

// MOCK DATA OBJECTS
const profile = {
	id: 'mock-user-id',
	firstName: 'Manny',
	lastName: 'Couceiro',
	email: 'mannyc@kmbs.konicaminolta.us',
	country: 'US'
};

const mockUserVaultType = 'self';

let mockGroupPermissions = [
	'frequency_settings',
	'advanced_device_management',
	'password_management',
	'password_remediation',
	'multi_admin_support',
	'policy_remediation'
];

if (typeof urlParams.get('mockPlanUltimate') === 'string') {
	switch (urlParams.get('mockPlanUltimate')) {
		case 'starter':
			mockGroupPermissions = [];
			break;
		case 'business':
			mockGroupPermissions = [
				'frequency_settings',
				'advanced_device_management',
				'password_management',
				'password_remediation',
				'multi_admin_support'
			];
			break;
		case 'enterprise':
		default:
			mockGroupPermissions = [
				'frequency_settings',
				'advanced_device_management',
				'password_management',
				'password_remediation',
				'multi_admin_support',
				'policy_remediation'
			];
			break;
	}
}

const selectedLicenseGroup = {
	SK: 'changeMeId',
	tenantId: 'changeMeId',
	name: 'ABC Company',
	licenseOwnerId: 'mock-user-id',
	catalogPublicId: localStorage.getItem('mockPlan') ?? PublicId.EnterpriseMonthly,
	// orderType: 'PRODUCTION',
	licenseType: localStorage.getItem('mockOrderType') ?? 'PRODUCTION',
	expirationDate: localStorage.getItem('mockExpired') ?? '2050-12-12T23:59:59.000Z',
	permissions: mockGroupPermissions,
	ownerId: 'mock-user-id',
	license: {
		ver: 1,
		quantity: 17,
		capacity: 50
	},
	isSelfManagedRequired: false,
	createdAt: 1614320869623,
	updatedAt: 1614320869623
};

const licenseGroups = [
	selectedLicenseGroup,
	{
		SK: 'five',
		tenantId: 'five',
		name: 'XYZ Company',
		licenseOwnerId: 'a3',
		catalogPublicId: PublicId.EnterpriseMonthly,
		// orderType: 'PRODUCTION',
		licenseType: 'PRODUCTION',
		expirationDate: '2050-12-12T23:59:59.000Z',
		permissions: [
			'frequency_settings',
			'advanced_device_management',
			'password_management',
			'password_remediation',
			'multi_admin_support',
			'policy_remediation'
		],
		license: {
			ver: 1,
			quantity: 19,
			capacity: 50
		},
		isSelfManagedRequired: false,
		createdAt: 1614320869623,
		updatedAt: 1614320869623
	},
	{
		SK: 'seven',
		tenantId: 'seven',
		name: 'Big Box Co.',
		licenseOwnerId: 'a3',
		catalogPublicId: PublicId.EnterpriseMonthly,
		// orderType: 'PRODUCTION',
		licenseType: 'PRODUCTION',
		expirationDate: undefined,
		permissions: [
			'frequency_settings',
			'advanced_device_management',
			'password_management',
			'password_remediation',
			'multi_admin_support',
			'policy_remediation'
		],
		license: {
			ver: 1,
			quantity: 14,
			capacity: 25
		},
		isSelfManagedRequired: false,
		createdAt: 1614320869623,
		updatedAt: 1614320869623
	},
	{
		SK: 'nine',
		tenantId: 'nine',
		name: 'EEL Inc.',
		licenseOwnerId: 'a3',
		catalogPublicId: PublicId.EnterpriseMonthly,
		// orderType: 'PRODUCTION',
		licenseType: 'PRODUCTION',
		expirationDate: '2050-12-12T23:59:59.000Z',
		permissions: [
			'frequency_settings',
			'advanced_device_management',
			'password_management',
			'password_remediation',
			'multi_admin_support',
			'policy_remediation'
		],
		license: {
			ver: 1,
			quantity: 119,
			capacity: 25
		},
		isSelfManagedRequired: false,
		createdAt: 1614320869623,
		updatedAt: 1614320869623
	}
];

let users = [
	{
		SK: profile.id,
		firstName: profile.firstName,
		lastName: profile.lastName,
		email: profile.email,
		vaultReset: false,
		createdAt: 1614202447928,
		permissions: {
			device_and_policy_management: true,
			user_management: true,
			license_management: true
		}
	},
	{
		SK: 'a3',
		firstName: 'John',
		lastName: 'Smith',
		email: 'jsmith@example.com',
		createdAt: 1616202447928,
		vaultReset: false,
		vaultType: 'self',
		permissions: {
			device_and_policy_management: true,
			user_management: true,
			license_management: true
		}
	},
	{
		SK: 'a5',
		firstName: 'Katherine',
		lastName: 'Pryde',
		email: 'kpryde@example.com',
		vaultReset: false,
		createdAt: 1616202447928,
		vaultType: 'self',
		permissions: {
			device_and_policy_management: true,
			user_management: true
		}
	},
	{
		SK: 'a11',
		firstName: 'Hank',
		lastName: 'McCoy',
		email: 'hmccoy@example.com',
		createdAt: 1616202447928,
		vaultType: 'self',
		permissions: {
			user_management: true
		}
	},
	{
		SK: 'a13',
		firstName: 'Eric',
		lastName: 'Brooks',
		email: 'ebrooks@example.com',
		createdAt: 1616202447928,
		vaultType: 'self',
		permissions: {
			license_management: true
		}
	},
	{
		SK: 'a19',
		firstName: 'Reed',
		lastName: 'Richards',
		email: 'rrichards@example.com',
		createdAt: 1616202447928,
		vaultType: 'self',
		permissions: {}
	},
	{
		SK: 'a21',
		firstName: 'Sean',
		lastName: 'Sullivan',
		email: 'ssullivan@example.com',
		createdAt: 1616202447928,
		vaultType: 'self',
		permissions: {}
	},
	{
		SK: 'a23',
		firstName: 'Tabrej',
		lastName: 'Chowdhury',
		email: 'tchowdhury@example.com',
		createdAt: 1616202447928,
		vaultType: 'self',
		permissions: {}
	},
	{
		SK: 'a25',
		firstName: 'Pavani',
		lastName: 'Ennamaneni',
		email: 'pennamaneni@example.com',
		createdAt: 1615202447928,
		vaultType: 'self',
		permissions: {}
	}
];

const pendingUsers = [
	{
		SK: 'jfrost@example.com',
		email: 'jfrost@example.com',
		dateAdded: 1611202447928,
		token: 'f024244021'
	},
	{
		SK: 'zjohnson@example.com',
		email: 'zjohnson@example.com',
		dateAdded: 1616202447528,
		token: 'f024244027'
	}
];

const XYZUsers = [
	{
		SK: profile.id,
		firstName: profile.firstName,
		lastName: profile.lastName,
		email: profile.email,
		vaultReset: false,
		createdAt: 1614202447928,
		permissions: {
			device_and_policy_management: true,
			user_management: true,
			license_management: true
		}
	},
	{
		SK: 'a3',
		firstName: 'John',
		lastName: 'Smith',
		email: 'jsmith@example.com',
		createdAt: 1616202447928,
		vaultReset: false,
		permissions: {
			device_and_policy_management: true,
			user_management: true,
			license_management: true
		}
	},
	{
		SK: 'a5',
		firstName: 'Katherine',
		lastName: 'Pryde',
		email: 'kpryde@example.com',
		vaultReset: true,
		createdAt: 1616202447928,
		permissions: {
			device_and_policy_management: true,
			user_management: true
		}
	},
	{
		SK: 'a11',
		firstName: 'Hank',
		lastName: 'McCoy',
		email: 'hmccoy@example.com',
		createdAt: 1616202447928,
		permissions: {
			user_management: true
		}
	},
	{
		SK: 'a13',
		firstName: 'Eric',
		lastName: 'Brooks',
		email: 'ebrooks@example.com',
		createdAt: 1616202447928,
		permissions: {
			license_management: true
		}
	},
	{
		SK: 'a19',
		firstName: 'Reed',
		lastName: 'Richards',
		email: 'rrichards@example.com',
		createdAt: 1616202447928,
		permissions: {}
	}
];

let devices = [
	{
		SK: 'A61F011000003',
		name: '287',
		deviceInfo: {
			deviceType: {
				family: 'EagleBKH'
			},
			serialNumber: 'A61F011000003'
		},
		friendlyName: 'First Floor - Lobby',
		// productLine: 'bizhub',
		secureStatus: 'pass',
		localIp: '10.15.212.45',
		policyId: '2457246306918-EfZCc5S6rkx1tPULUVNJAe',
		onlineStatus: false,
		onlineStatusTs: 1616202497928,
		active: true,
		deviceGroupId: undefined,
		createdAt: 1616202497928,
		assessmentTs: Date.now() - 60_000 * 61
	},
	{
		SK: 'A61F011000005',
		name: '364e',
		deviceInfo: {
			deviceType: {
				family: 'EagleBKH_ZPlus'
			},
			serialNumber: 'A61F011000005'
		},
		// friendlyName: '',
		// productLine: 'bizhub',
		secureStatus: 'pass',
		localIp: '10.15.212.44',
		policyId: '2457246306918-EfZCc5S6rkx1tPULUVNJAe',
		onlineStatus: true,
		onlineStatusTs: 1616202496928,
		active: true,
		deviceGroupId: undefined,
		createdAt: 1616202496928,
		assessmentTs: Date.now() - 60_000 * 9
	},
	{
		SK: 'A61F011000007',
		name: '364e',
		deviceInfo: {
			deviceType: {
				family: 'DonauBK'
			},
			serialNumber: 'A61F011000007'
		},
		friendlyName: 'First Floor - Lab',
		// productLine: 'bizhub',
		secureStatus: 'fail',
		localIp: '10.15.212.43',
		policyId: '2457246319249-1Kfn69QQgj9qV9qq1qc2Rn',
		onlineStatus: true,
		onlineStatusTs: 1616202495928,
		active: true,
		deviceGroupId: 'deviceGroup-1',
		createdAt: 1616202495928,
		assessmentTs: Date.now() - 60_000 * 9
	},
	{
		SK: 'A61F011000009',
		name: '4052',
		deviceInfo: {
			deviceType: {
				family: 'VenusMLK'
			},
			serialNumber: 'A61F011000009'
		},
		// friendlyName: '',
		// productLine: 'bizhub',
		secureStatus: 'fail',
		policyId: '2457249195177-3h8tGRxCXexzPuNdyhHduD',
		onlineStatus: true,
		localIp: '10.15.212.58',
		onlineStatusTs: 1616202447928,
		active: true,
		deviceGroupId: undefined,
		createdAt: 1616202447928,
		assessmentTs: Date.now() - 60_000 * 9,
		policyFails: [
			{
				"msg": 'jblgenaball is \'{"audit_log":false,"enable":true,"counting_log":false,"overwrite":false,"accounting_log":false,"tx_method":"menual_xml"}\' but should be \'{"counting_log":true,"tx_method":"manual","audit_log":false,"overwrite":true,"enable":true,"accounting_log":true}\'',
				"notRemediable": false,
				"noApiAvailable": false,
				"cantDisableUnsupportedFeature": false,
				"deviceSetting": {
				"counting_log": false,
				"tx_method": 'menual_xml',
				"audit_log": false,
				"overwrite": false,
				"enable": true,
				"accounting_log": false
				},
				"msgKey": 'jblgenaballFail',
				"requiredSetting": {
				"counting_log": true,
				"tx_method": 'manual',
				"audit_log": false,
				"overwrite": true,
				"enable": true,
				"accounting_log": true
				},
				"policyItemKey": 'jblgenaball'
			},
			{
				"requiredSetting": 60,
				"msg": "encryptpdf is '45' but should be '60'",
				"notRemediable": false,
				"noApiAvailable": false,
				"cantDisableUnsupportedFeature": false,
				"policyItemKey": "encryptpdf",
				"msgKey": "encryptpdfFail",
				"deviceSetting": 45
			},
			{
				"requiredSetting": 60,
				"msg": "idandprint is 'false' but should be '60'",
				"notRemediable": false,
				"noApiAvailable": false,
				"policyItemKey": "idandprint",
				"cantDisableUnsupportedFeature": false,
				"msgKey": "idandprintFail",
				"deviceSetting": false
			},
			{
				"requiredSetting": 60,
				"msg": "securebox is 'Day30' but should be '60'",
				"notRemediable": false,
				"noApiAvailable": false,
				"cantDisableUnsupportedFeature": false,
				"msgKey": "secureboxFail",
				"policyItemKey": "securebox",
				"deviceSetting": "Day30"
			},
			{
				"requiredSetting": true,
				"msg": "hddencrypt is 'false' but should be 'true'",
				"notRemediable": false,
				"noApiAvailable": false,
				"msgKey": "hddencryptFail",
				"policyItemKey": "hddencrypt",
				"cantDisableUnsupportedFeature": false,
				"deviceSetting": false
			},
			{
				"requiredSetting": true,
				"msg": "hddpass is 'false' but should be 'true'",
				"notRemediable": false,
				"noApiAvailable": false,
				"cantDisableUnsupportedFeature": false,
				"msgKey": "hddpassFail",
				"policyItemKey": "hddpass",
				"deviceSetting": false
			},
			{
				"msg": "crtenab is 'no_api' but should be 'UserAdmin'",
				"notRemediable": false,
				"noApiAvailable": true,
				"cantDisableUnsupportedFeature": false,
				"policyItemKey": "crtenab",
				"msgKey": "crtenabFail",
				"requiredSetting": "UserAdmin",
				"deviceSetting": "no_api"
			},
			{
				"msg": "tls12 is 'no_api' but should be 'true'",
				"notRemediable": false,
				"requiredSetting": true,
				"noApiAvailable": true,
				"cantDisableUnsupportedFeature": false,
				"msgKey": "tls12Fail",
				"policyItemKey": "tls12",
				"deviceSetting": "no_api",
			},
			{
				"msg": "ftsvenab is 'no_api' but should be 'true'",
				"notRemediable": false,
				"noApiAvailable": true,
				"cantDisableUnsupportedFeature": false,
				"deviceSetting": "no_api",
				"msgKey": "ftsvenabFail",
				"requiredSetting": true,
				"policyItemKey": "ftsvenab",
			},
			{
				"msg": "fttxenab is 'no_api' but should be 'true'",
				"notRemediable": false,
				"noApiAvailable": true,
				"cantDisableUnsupportedFeature": false,
				"deviceSetting": "no_api",
				"msgKey": "fttxenabFail",
				"requiredSetting": true,
				"policyItemKey": "fttxenab"
			},
			{
				"requiredSetting": true,
				"msg": "tls13 is 'no_api' but should be 'true'",
				"notRemediable": false,
				"noApiAvailable": true,
				"msgKey": "tls13Fail",
				"policyItemKey": "tls13",
				"cantDisableUnsupportedFeature": false,
				"deviceSetting": "no_api",
			},
			{
				"requiredSetting": 60,
				"msg": "admtmout is 'no_api' but should be '60'",
				"notRemediable": false,
				"noApiAvailable": true,
				"msgKey": "admtmoutFail",
				"policyItemKey": "admtmout",
				"cantDisableUnsupportedFeature": false,
				"deviceSetting": "no_api",
			},
			{
				"requiredSetting": 8,
				"msg": "usrtmout is 'no_api' but should be '8'",
				"notRemediable": false,
				"msgKey": "usrtmoutFail",
				"noApiAvailable": true,
				"policyItemKey": "usrtmout",
				"cantDisableUnsupportedFeature": false,
				"deviceSetting": "no_api",
			},
			{
				"requiredSetting": true,
				"msg": "jblgenab is 'no_api' but should be 'true'",
				"policyItemKey": 'jblgenab',
				"notRemediable": false,
				"noApiAvailable": true,
				"cantDisableUnsupportedFeature": false,
				"deviceSetting": "no_api",
			},
			{
				msg:
					'ultilicensed is \n          \'{"licensed":false,"supported":true}\' \n          but should be \'{"licensed":true,"supported":true}\'',
				deviceSetting: {
					licensed: false,
					supported: false
				},
				msgKey: 'ultilicensedFail',
				requiredSetting: {
					licensed: true,
					supported: true
				},
				policyItemKey: 'ultilicensed',
				noApiAvailable: true
			},
			{
				msg: '',
				"deviceSetting": "no_api",
				msgKey: 'rtscanFail',
				requiredSetting: false,
				policyItemKey: 'rtscan'
			},
			{
				msg: '',
				deviceSetting: 'Level2',
				msgKey: 'rtscanFail',
				requiredSetting: 'Level1',
				policyItemKey: 'rtscn/VirusDetectedJob'
			},
			{
				msg: '',
				deviceSetting: 'no_api',
				msgKey: 'regscanFail',
				requiredSetting: { enable: false, schedule: { cycle: '--' } },
				policyItemKey: 'regscan'
			},
		]
	},
	{
		SK: 'A61F011000011',
		name: '4750',
		deviceInfo: {
			deviceType: {
				family: 'DenebMLK'
			},
			serialNumber: 'A61F011000011'
		},
		// friendlyName: '',
		// productLine: 'bizhub',
		secureStatus: 'pass',
		localIp: '10.15.212.59',
		policyId: '2457246306918-EfZCc5S6rkx1tPULUVNJAe',
		onlineStatus: true,
		onlineStatusTs: 1616202397928,
		active: true,
		deviceGroupId: undefined,
		createdAt: 1616202397928,
		assessmentTs: Date.now() - 60_000 * 9
	},
	{
		SK: 'A61F011000013',
		name: 'C287',
		deviceInfo: {
			deviceType: {
				family: 'EagleBKH_ZPlus'
			},
			serialNumber: 'A61F011000013'
		},
		friendlyName: 'Warehouse',
		// productLine: 'workplacehub',
		localIp: '10.15.212.60',
		secureStatus: undefined,
		policyId: undefined,
		onlineStatus: true,
		onlineStatusTs: 1616202497928,
		active: true,
		deviceGroupId: undefined,
		createdAt: 1616202497928,
		assessmentTs: undefined
	},
	{
		SK: 'A61F011000015',
		name: 'C287',
		deviceInfo: {
			deviceType: {
				family: 'WHUB'
			},
			serialNumber: 'A61F011000015'
		},
		friendlyName: 'Third Floor - Lobby',
		// productLine: 'workplacehub',
		secureStatus: 'pass',
		policyId: '2457246319249-1Kfn69QQgj9qV9qq1qc2Rn',
		localIp: '10.15.212.61',
		onlineStatus: true,
		onlineStatusTs: 1616202497118,
		active: true,
		deviceGroupId: 'deviceGroup-1',
		createdAt: 1616202497118,
		assessmentTs: Date.now() - 60_000 * 9
	},
	{
		SK: 'A61F011000017',
		name: 'C300i',
		deviceInfo: {
			deviceType: {
				family: 'EagleBKH_ZPlus'
			},
			serialNumber: 'A61F011000017'
		},
		// friendlyName: '',
		// productLine: 'workplacehub',
		secureStatus: undefined,
		policyId: '2457246319249-1Kfn69QQgj9qV9qq1qc2Rn',
		onlineStatus: true,
		onlineStatusTs: 1616101497928,
		localIp: '10.15.212.62',
		active: true,
		deviceGroupId: 'deviceGroup-1',
		createdAt: 1616101497928,
		assessmentTs: undefined
	},
	{
		SK: 'A61F011000619',
		name: 'C666',
		deviceInfo: {
			deviceType: {
				family: 'EagleBKH_ZPlus'
			},
			serialNumber: 'A61F011000619'
		},
		// friendlyName: '',
		// productLine: 'workplacehub',
		secureStatus: 'fail',
		policyId: '2457247462398-BgsepVeyiSx1NmAf3MxJPb',
		localIp: '10.15.212.63',
		onlineStatus: true,
		onlineStatusTs: 1616111497928,
		active: true,
		deviceGroupId: 'deviceGroup-2',
		createdAt: 1616111497928,
		assessmentTs: Date.now() - 60_000 * 9
	},
	{
		SK: 'A61F011000621',
		name: 'C666',
		deviceInfo: {
			deviceType: {
				family: 'EagleBKH_ZPlus'
			},
			serialNumber: 'A61F011000621'
		},
		// friendlyName: '',
		// productLine: 'workplacehub',
		secureStatus: undefined,
		policyId: undefined,
		onlineStatus: undefined,
		onlineStatusTs: undefined,
		active: false,
		deviceGroupId: undefined,
		createdAt: 1616111497928,
		assessmentTs: Date.now() - 60_000 * 9
	}
];

let deviceGroups = [
	{
		SK: 'deviceGroup-1',
		name: 'HQ Building 101',
		policyId: '2457246319249-1Kfn69QQgj9qV9qq1qc2Rn',
		updatedAt: 1619483870584
	},
	{
		SK: 'deviceGroup-2',
		name: 'HQ Building 201',
		policyId: '2457247462398-BgsepVeyiSx1NmAf3MxJPb',
		updatedAt: 1619483860584
	},
	{
		SK: 'deviceGroup-3',
		name: 'HQ Building 301',
		updatedAt: 1619483850584
	}
];

let policies = [
	{
		SK: '2457246306918-EfZCc5S6rkx1tPULUVNJAe',
		policyName: 'Random Password Policy',
		policyItems: [
			{
				name: 'SSL 3.0 Enabled',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '2030',
				requirement: 'true',
				remediation: false,
				key: 'ssl'
			}
		],
		passwordConfig: {
			blacklist: ['1234567812345678', 'password1'],
			passwordGenerationType: 'random',
			randomPasswordOptions: {
				lowerCase: 1,
				symbol: 1,
				length: 19
			},
			manualPasswordOptions: {
				lowerCase: 1,
				symbol: 1,
				length: 19
			}
		},
		timing: {
			pingInterval: 60,
			telemetryCheckInterval: 60,
			offlineThreshold: 3
		},
		policyTs: 1616200477900
	},
	{
		SK: '2457246319249-1Kfn69QQgj9qV9qq1qc2Rn',
		policyName: 'Policy 1',
		policyItems: [],
		timing: {
			pingInterval: 60,
			telemetryCheckInterval: 60,
			offlineThreshold: 3
		},
		policyTs: 1616902177900
	},
	{
		SK: '2457247462398-BgsepVeyiSx1NmAf3MxJPb',
		policyName: 'Holly Molly',
		policyItems: [
			{
				name: 'Admin Password',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1010',
				requirement: 'true',
				remediation: false,
				key: 'adminpw'
			},
			{
				name: 'Admin Password Rules',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1020',
				requirement: 'true',
				remediation: false,
				key: 'adminpwr'
			},
			{
				name: 'Auto Document Deletion',
				options: [
					{ val: '-1', tag: 'timed' },
					{ val: '1', tag: 'timed' },
					{ val: '5', tag: 'timed' },
					{ val: '15', tag: 'timed' },
					{ val: '30', tag: 'timed' },
					{ val: '45', tag: 'timed' },
					{ val: '60', tag: 'timed' },
					{ val: '120', tag: 'timed' },
					{ val: '240', tag: 'timed' },
					{ val: '480', tag: 'timed' },
					{ val: '1440', tag: 'timed' },
					{ val: '2880', tag: 'timed' },
					{ val: '4320', tag: 'timed' },
					{ val: '10080', tag: 'timed' },
					{ val: '43200', tag: 'timed' }
				],
				policyItemId: '1030',
				requirement: '1',
				remediation: false,
				key: 'autodoc'
			},
			{
				name: 'Backup Schedule',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1040',
				requirement: 'true',
				remediation: false,
				key: 'backup'
			},
			{
				name: 'Encrypted PDF Deletion',
				options: [
					{ val: '-1', tag: 'timed' },
					{ val: '1', tag: 'timed' },
					{ val: '5', tag: 'timed' },
					{ val: '15', tag: 'timed' },
					{ val: '30', tag: 'timed' },
					{ val: '45', tag: 'timed' },
					{ val: '60', tag: 'timed' },
					{ val: '120', tag: 'timed' },
					{ val: '240', tag: 'timed' },
					{ val: '480', tag: 'timed' },
					{ val: '1440', tag: 'timed' },
					{ val: '2880', tag: 'timed' },
					{ val: '4320', tag: 'timed' },
					{ val: '10080', tag: 'timed' },
					{ val: '43200', tag: 'timed' }
				],
				policyItemId: '1060',
				requirement: '1',
				remediation: false,
				key: 'encryptpdf'
			},
			{
				name: 'HDD Encryption',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1070',
				requirement: 'false',
				remediation: false,
				key: 'hddencrypt'
			},
			{
				name: 'ID + Print Deletion',
				options: [
					{ val: '-1', tag: 'timed' },
					{ val: '1', tag: 'timed' },
					{ val: '5', tag: 'timed' },
					{ val: '15', tag: 'timed' },
					{ val: '30', tag: 'timed' },
					{ val: '45', tag: 'timed' },
					{ val: '60', tag: 'timed' },
					{ val: '120', tag: 'timed' },
					{ val: '240', tag: 'timed' },
					{ val: '480', tag: 'timed' },
					{ val: '1440', tag: 'timed' },
					{ val: '2880', tag: 'timed' },
					{ val: '4320', tag: 'timed' },
					{ val: '10080', tag: 'timed' },
					{ val: '43200', tag: 'timed' }
				],
				policyItemId: '1080',
				requirement: '5',
				remediation: false,
				key: 'idandprint'
			},
			{
				name: 'Public Authentication',
				options: [
					{ val: '1', tag: 'off' },
					{ val: '2', tag: 'onwi' },
					{ val: '3', tag: 'onwo' },
					{ val: 'false', tag: 'authoff' }
				],
				policyItemId: '1090',
				requirement: '1',
				remediation: false,
				key: 'publicauth'
			},
			{
				name: 'Temporary Data Overwrite',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1110',
				requirement: 'true',
				remediation: false,
				key: 'tempdata'
			},
			{
				name: 'User Authentication',
				options: [
					{ val: '1', tag: 'off' },
					{ val: '2', tag: 'onwi' },
					{ val: '3', tag: 'onwo' },
					{ val: 'false', tag: 'authoff' }
				],
				policyItemId: '1120',
				requirement: '1',
				remediation: false,
				key: 'userauth'
			},
			{
				name: 'HDD Lock Password',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '2020',
				requirement: 'true',
				remediation: false,
				key: 'hdpw'
			},
			{
				name: 'SSL 3.0 Enabled',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '2030',
				requirement: 'true',
				remediation: false,
				key: 'ssl'
			}
		],
		timing: {
			pingInterval: 60,
			telemetryCheckInterval: 60,
			offlineThreshold: 3
		},
		policyTs: 1617201477900
	},
	{
		SK: '2457249195177-3h8tGRxCXexzPuNdyhHduD',
		policyName: "Sean's Default",
		policyItems: [
			{
				name: 'Admin Password',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1010',
				requirement: 'true',
				remediation: false,
				key: 'adminpw'
			},
			{
				name: 'Admin Password Rules',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1020',
				requirement: 'true',
				remediation: false,
				key: 'adminpwr'
			},
			{
				name: 'Backup Schedule',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1040',
				requirement: 'true',
				remediation: false,
				key: 'backup'
			},
			{
				name: 'Mode using SSL/TLS',
				options: [
					{ val: 'Admin', tag: 'crtenab-admin-mode' },
					{ val: 'UserAdmin', tag: 'crtenab-admin-mode-and-user-mode' }
				],
				policyItemId: '2040',
				requirement: 'UserAdmin',
				remediation: false,
				key: 'crtenab'
			},
			{
				options: [
					{ val: true, tag: 'req' },
					{ val: false, tag: 'noreq' }
				],
				requirement: 'true',
				remediation: false,
				key: 'tls11'
			},
			{
				options: [
					{ val: true, tag: 'req' },
					{ val: false, tag: 'noreq' }
				],
				requirement: 'true',
				remediation: false,
				key: 'tls12'
			}
		],
		timing: {
			pingInterval: 60,
			telemetryCheckInterval: 60,
			offlineThreshold: 3
		},
		policyTs: 1617202377900
	},
	{
		SK: '2457249195177-3h8tGRxCXexzPuNdyhHduJ',
		policyName: 'Standard Policy',
		policyItems: [
			{
				name: 'Admin Password',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1010',
				requirement: 'true',
				remediation: false,
				key: 'adminpw'
			},
			{
				name: 'Job Log',
				options: [{ val: 'true', tag: 'req' }],
				policyItemId: '1290',
				requirement: true,
				remediation: false,
				key: 'jblgenab'
			},
			// {
			// 	name: 'Job Log',
			// 	options: [
			// 		{ val: 'true', tag: 'req' }
			// 	],
			// 	policyItemId: '1290',
			// 	requirement: {
			// 		enable: true,
			// 		// overwrite: true,
			// 		// tx_method: 'manual',
			// 		// accounting_log: true,
			// 		// counting_log: true,
			// 		// audit_log: false
			// 	},
			// 	remediation: false,
			// 	key: 'jblgenaball'
			// },
			// {
			// 	name: 'Job Log',
			// 	options: [
			// 		{ val: 'true', tag: 'req' }
			// 	],
			// 	policyItemId: '1890',
			// 	requirement: 'true',
			// 	remediation: false,
			// 	key: 'acctlog'
			// },
			// {
			// 	name: 'Job Log',
			// 	options: [
			// 		{ val: 'true', tag: 'req' }
			// 	],
			// 	policyItemId: '1910',
			// 	requirement: 'true',
			// 	remediation: false,
			// 	key: 'countlog'
			// },
			{
				name: 'Admin Password Rules',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1020',
				requirement: 'true',
				remediation: false,
				key: 'adminpwr'
			},
			{
				name: 'Backup Schedule',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1040',
				requirement: 'true',
				remediation: false,
				key: 'backup'
			},
			{
				policyItemId: '1050',
				options: [
					{ val: true, tag: 'req' },
					{ val: false, tag: 'noreq' }
				],
				requirement: 'true',
				remediation: false,
				key: 'ultilicensed'
			},
			{
				policyItemId: '1060',
				requirement: 'true',
				options: [
					{ val: true, tag: 'req' },
					{ val: false, tag: 'noreq' }
				],
				key: 'patternfileversion'
			}
		],
		timing: {
			pingInterval: 30,
			telemetryCheckInterval: 45,
			offlineThreshold: 3
		},
		policyTs: 1617202497928
	},
	{
		SK: '2457249195177-3h8tGRxCXexzPuNdyhHduQ',
		policyName: 'Advanced Policy',
		policyItems: [
			{
				name: 'Admin Password',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1010',
				requirement: 'true',
				remediation: false,
				key: 'adminpw'
			},
			{
				name: 'Admin Password Rules',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1020',
				requirement: 'true',
				remediation: false,
				key: 'adminpwr'
			},
			{
				name: 'Backup Schedule',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1040',
				requirement: 'true',
				remediation: false,
				key: 'backup'
			}
		],
		timing: {
			pingInterval: 5,
			telemetryCheckInterval: 5,
			offlineThreshold: 3
		},
		policyTs: 1617202487900
	},
	{
		SK: '2457408410923-8eRRqpJ7JRkNbVDjBh23Kd',
		policyName: 'Default Policy',
		policyItems: [
			{
				name: 'Admin Password',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1010',
				requirement: 'true',
				remediation: false,
				key: 'adminpw'
			},
			{
				name: 'Backup Schedule',
				options: [
					{ val: 'true', tag: 'req' },
					{ val: 'false', tag: 'noreq' }
				],
				policyItemId: '1040',
				requirement: 'true',
				remediation: false,
				key: 'backup'
			},
			{
				name: 'Public Authentication',
				options: [
					{ val: '1', tag: 'off' },
					{ val: '2', tag: 'onwi' },
					{ val: '3', tag: 'onwo' },
					{ val: 'false', tag: 'authoff' }
				],
				policyItemId: '1090',
				requirement: '1',
				remediation: false,
				key: 'publicauth'
			},
			{
				name: 'User Authentication',
				options: [
					{ val: '1', tag: 'off' },
					{ val: '2', tag: 'onwi' },
					{ val: '3', tag: 'onwo' },
					{ val: 'false', tag: 'authoff' }
				],
				policyItemId: '1120',
				requirement: '2',
				remediation: false,
				key: 'userauth'
			},
			{
				options: [
					{ val: true, tag: 'req' },
					{ val: false, tag: 'noreq' }
				],
				requirement: 'true',
				remediation: false,
				key: 'tls13'
			}
			// {
			// 	name: 'SNMP v3',
			// 	options: [
			// 		{ val: true, tag: 'req' },
			// 		{ val: false, tag: 'noreq' }
			// 	],
			// 	policyItemId: '1220',
			// 	requirement: true,
			// 	remediation: false,
			// 	key: 'snmpv3'
			// }
		],
		timing: {
			pingInterval: 15,
			telemetryCheckInterval: 15,
			offlineThreshold: 3
		},
		policyTs: 1617202477900
	}
];

const logs = [
	{
		SK: '2378530591375-EM1pcipfxu61t3hATL9c5m',
		id: '2378530591375-EM1pcipfxu61t3hATL9c5m',
		type: 'device',
		createdAt: 1692378208625,
		severity: 'low',
		msgKey: 'deviceSettingRemediated',
		info: {
            msgParm2: 'On',
            msgParm1: 'jblg/InspectLog',
            msgParm3: 'Off',
            deviceId: 'AAJN011001611',
            deviceName: 'C4050i lab it6'
          }
	  },
	{
		SK: '2378621912738-FXfyev8tdLL5pXTMPSkd7X',
		id: '2378621912738-FXfyev8tdLL5pXTMPSkd7X',
		type: 'device',
		createdAt: 1692286887262,
		severity: 'high',
		msgKey: 'deviceFailedAssessment',
		info: {
		  policyFails: [
			{
			  msg: 'patternfilewarning is \'true\' but should be \'false\'',
			  notRemediable: false,
			  noApiAvailable: false,
			  cantDisableUnsupportedFeature: false,
			  deviceSetting: {},
			  msgKey: 'patternfilewarningFail',
			  requiredSetting: true,
			  upgradeFirmware: true,
			  policyItemKey: 'patternfilewarning'
			},
			{
                msg: 'rtscn/VirusDetectedJob is \'Level1\' but should be \'Level2\'',
                notRemediable: false,
                noApiAvailable: false,
                cantDisableUnsupportedFeature: false,
                deviceSetting: 'Level1',
                msgKey: 'rtscn/VirusDetectedJobFail',
                requiredSetting: 'Level2',
                upgradeFirmware: true,
                policyItemKey: 'rtscn/VirusDetectedJob'
              }
		  ],
		  deviceFamily: 'EagleL',
		  deviceName: 'C300i C300i Ramsey',
		  deviceId: 'AA2K011010707'
		}
	},
	{
		id: '2378709870817-NwYoM6N7FVaziLbfWMsLdh',
		type: 'device',
		createdAt: '1692198929183',
		severity: 'low',
		msgKey: 'deviceSettingRemediated',
		SK:'2378709870817-NwYoM6N7FVaziLbfWMsLdh',
		info: {
			msgParm2: 'Day1',
			msgParm1: 'mfpsharedfolder',
			msgParm3: 'Day7',
			deviceId: 'A61F011000009',
			deviceName: 'C360i'
		}
	},
	{
		id: '2378709872086-8AerD7qdfRPDKMoaQcPfeC',
		type: 'device',
		createdAt: 1692198927914,
		SK: '2378709872086-8AerD7qdfRPDKMoaQcPfeC',
		severity: 'low',
		msgKey: 'deviceSettingRemediated',
		info: {
		  msgParm2: true,
		  msgParm1: 'patternfilewarning',
		  msgParm3: false,
		  deviceId: 'A61F011000009',
		  deviceName: 'C360i'
		}
	},
	{
		info: {
			policyFails: [
					{
					  "msg": 'jblgenaball is \'{"audit_log":false,"enable":true,"counting_log":false,"overwrite":false,"accounting_log":false,"tx_method":"menual_xml"}\' but should be \'{"counting_log":true,"tx_method":"manual","audit_log":false,"overwrite":true,"enable":true,"accounting_log":true}\'',
					  "notRemediable": false,
					  "noApiAvailable": false,
					  "cantDisableUnsupportedFeature": false,
					  "deviceSetting": {
						"counting_log": false,
						"tx_method": 'menual_xml',
						"audit_log": false,
						"overwrite": false,
						"enable": true,
						"accounting_log": false
					  },
					  "msgKey": 'jblgenaballFail',
					  "requiredSetting": {
						"counting_log": true,
						"tx_method": 'manual',
						"audit_log": false,
						"overwrite": true,
						"enable": true,
						"accounting_log": true
					  },
					  "policyItemKey": 'jblgenaball'
					}
				,
				{
					"requiredSetting": 60,
					"msg": "encryptpdf is '45' but should be '60'",
					"notRemediable": false,
					"noApiAvailable": false,
					"cantDisableUnsupportedFeature": false,
					"policyItemKey": "encryptpdf",
					"msgKey": "encryptpdfFail",
					"deviceSetting": 45
				},
				{
					"requiredSetting": 60,
					"msg": "idandprint is 'false' but should be '60'",
					"notRemediable": false,
					"noApiAvailable": false,
					"policyItemKey": "idandprint",
					"cantDisableUnsupportedFeature": false,
					"msgKey": "idandprintFail",
					"deviceSetting": false
				},
				{
					"requiredSetting": 60,
					"msg": "securebox is 'Day30' but should be '60'",
					"notRemediable": false,
					"noApiAvailable": false,
					"cantDisableUnsupportedFeature": false,
					"msgKey": "secureboxFail",
					"policyItemKey": "securebox",
					"deviceSetting": "Day30"
				},
				{
					"requiredSetting": true,
					"msg": "hddencrypt is 'false' but should be 'true'",
					"notRemediable": false,
					"noApiAvailable": false,
					"msgKey": "hddencryptFail",
					"policyItemKey": "hddencrypt",
					"cantDisableUnsupportedFeature": false,
					"deviceSetting": false
				},
				{
					"requiredSetting": true,
					"msg": "hddpass is 'false' but should be 'true'",
					"notRemediable": false,
					"noApiAvailable": false,
					"cantDisableUnsupportedFeature": false,
					"msgKey": "hddpassFail",
					"policyItemKey": "hddpass",
					"deviceSetting": false
				},
				{
					"msg": "crtenab is 'no_api' but should be 'UserAdmin'",
					"notRemediable": false,
					"noApiAvailable": true,
					"cantDisableUnsupportedFeature": false,
					"policyItemKey": "crtenab",
					"msgKey": "crtenabFail",
					"requiredSetting": "UserAdmin",
					"deviceSetting": "no_api"
				},
				{
					"msg": "tls12 is 'no_api' but should be 'true'",
					"notRemediable": false,
					"requiredSetting": true,
					"noApiAvailable": true,
					"cantDisableUnsupportedFeature": false,
					"msgKey": "tls12Fail",
					"policyItemKey": "tls12",
					"deviceSetting": "no_api",
				},
				{
					"msg": "ftsvenab is 'no_api' but should be 'true'",
					"notRemediable": false,
					"noApiAvailable": true,
					"cantDisableUnsupportedFeature": false,
					"deviceSetting": "no_api",
					"msgKey": "ftsvenabFail",
					"requiredSetting": true,
					"policyItemKey": "ftsvenab",
				},
				{
					"msg": "fttxenab is 'no_api' but should be 'true'",
					"notRemediable": false,
					"noApiAvailable": true,
					"cantDisableUnsupportedFeature": false,
					"deviceSetting": "no_api",
					"msgKey": "fttxenabFail",
					"requiredSetting": true,
					"policyItemKey": "fttxenab"
				},
				{
					"requiredSetting": true,
					"msg": "tls13 is 'no_api' but should be 'true'",
					"notRemediable": false,
					"noApiAvailable": true,
					"msgKey": "tls13Fail",
					"policyItemKey": "tls13",
					"cantDisableUnsupportedFeature": false,
					"deviceSetting": "no_api",
				},
				{
					"requiredSetting": 60,
					"msg": "admtmout is 'no_api' but should be '60'",
					"notRemediable": false,
					"noApiAvailable": true,
					"msgKey": "admtmoutFail",
					"policyItemKey": "admtmout",
					"cantDisableUnsupportedFeature": false,
					"deviceSetting": "no_api",
				},
				{
					"requiredSetting": 8,
					"msg": "usrtmout is 'no_api' but should be '8'",
					"notRemediable": false,
					"msgKey": "usrtmoutFail",
					"noApiAvailable": true,
					"policyItemKey": "usrtmout",
					"cantDisableUnsupportedFeature": false,
					"deviceSetting": "no_api",
				},
				{
					"requiredSetting": true,
					"msg": "jblgenab is 'no_api' but should be 'true'",
					"policyItemKey": 'jblgenab',
					"notRemediable": false,
					"noApiAvailable": true,
					"cantDisableUnsupportedFeature": false,
					"deviceSetting": "no_api",
				},
				{
					msg:
						'ultilicensed is \n          \'{"licensed":false,"supported":true}\' \n          but should be \'{"licensed":true,"supported":true}\'',
					deviceSetting: {
						licensed: false,
						supported: false
					},
					msgKey: 'ultilicensedFail',
					requiredSetting: {
						licensed: true,
						supported: true
					},
					policyItemKey: 'ultilicensed',
					noApiAvailable: true
				},
				{
					msg: '',
					"deviceSetting": "no_api",
					msgKey: 'rtscanFail',
					requiredSetting: false,
					policyItemKey: 'rtscan'
				},
				{
					msg: '',
					deviceSetting: 'Level2',
					msgKey: 'rtscanFail',
					requiredSetting: 'Level1',
					policyItemKey: 'rtscn/VirusDetectedJob'
				},
				{
					msg: '',
					deviceSetting: 'no_api',
					msgKey: 'regscanFail',
					requiredSetting: { enable: false, schedule: { cycle: '--' } },
					policyItemKey: 'regscan'
				},
			],
			deviceName: '4052',
			deviceFamily: 'KronosS',
			deviceId: 'A61F011000009'
		},
		logId: '2388277254072-1jx3qUmM17UhAubCtrUoJA',
		msgKey: 'deviceFailedAssessment',
		createdAt: '1682631545928',
		SK: '2388277254072-1jx3qUmM17UhAubCtrUoJA',
		message: 'Device 4052 (A61F011000009) did not pass its policy assessment.',
		id: 'A61F011000009',
		PK: 'T:NR00001288-SGAAA01',
		severity: 'high',
		type: 'device'
	},
	{
		info: {
			policyFails: [
				{
					msg:
						'ultilicensed is \n          \'{"licensed":false,"supported":true}\' \n          but should be \'{"licensed":true,"supported":true}\'',
					deviceSetting: {
						licensed: false,
						supported: true
					},
					msgKey: 'ultilicensedFail',
					requiredSetting: {
						licensed: true,
						supported: true
					},
					policyItemKey: 'ultilicensed'
				},
				{
					msg: '',
					deviceSetting: {
						enable: true,
						schedule: {
							cycle: 'Daily',
							start_time: {
								hour: 16,
								minute: 20
							}
						}
					},
					msgKey: 'regscanFail',
					requiredSetting: { enable: false, schedule: { cycle: '--' } },
					policyItemKey: 'regscan'
				},
				{
					msg: '',
					deviceSetting: true,
					msgKey: 'rtscanFail',
					requiredSetting: false,
					policyItemKey: 'rtscan'
				}
			],
			deviceName: 'C4050i lab it6',
			deviceId: 'AAJN011001611'
		},
		logId: '2388277254072-1jx3qUmM17UhAubCtrUoo7',
		msgKey: 'deviceFailedAssessment',
		createdAt: '1682631545928',
		SK: 'L:2388277254072-1jx3qUmM17UhAubCtrUoo7',
		message: 'Device C4050i lab it6 (AAJN011001611) did not pass its policy assessment.',
		id: 'AAJN011001611',
		PK: 'T:NR00001288-SGAAA01',
		severity: 'high',
		type: 'device'
	},
	{
		info: {
			msgParm2: "{'rtscan': 'On'}",
			msgParm1: 'rtscan',
			msgParm3: "{'rtscan': 'Off'}",
			deviceId: 'AAJN011001611',
			deviceName: 'C4050i lab it6'
		},
		logId: '2387951921128-2iSPg5otMYeUfDGuQNAvMF',
		msgKey: 'deviceSettingRemediated',
		createdAt: '1682631545927',
		SK: '2387951921128-2iSPg5otMYeUfDGuQNAvMF',
		message:
			"Device DeviceName (AAJN011001611) setting rtscan remediated from '{'rtscan': 'On'}' to '{'rtscan': 'Off'}'",
		id: 'AAJN011001611',
		PK: 'NR00001288-SGAAA01',
		severity: 'low',
		type: 'device'
	},
	{
		info: {
			msgParm2:
				"{'regscan': {'schedule': {'start_time': {'hour': '3', 'minute': '0'}, 'day_of_week': 'Sunday', 'cycle': 'Weekly'}, 'enable': True}}",
			msgParm1: 'regscan',
			msgParm3: "{'regscan': {'schedule': {'cycle': '--'}, 'enable': False}}",
			deviceId: 'AAJN011001611',
			deviceName: 'C4050i lab it6'
		},
		logId: '2388545341763-3aUbs4axk545RwR4VXAoZp',
		msgKey: 'deviceSettingRemediated',
		createdAt: '1682631545926',
		SK: '2388545341763-3aUbs4axk545RwR4VXAoZp',
		message:
			"Device DeviceName (AAJN011001611) setting regscan remediated from '{'regscan': {'schedule': {'start_time': {'hour': '3', 'minute': '0'}, 'day_of_week': 'Sunday', 'cycle': 'Weekly'}, 'enable': True}}' to '{'regscan': {'schedule': {'cycle': '--'}, 'enable': False}}'",
		id: 'AAJN011001611',
		PK: 'NR00001505-SGENNFR',
		severity: 'low',
		type: 'device'
	},
	{
		info: {
			msgParm2: "{'tls11': 'false', 'tls10': 'true', 'tls13': 'false', 'tls12': 'false', 'ssl3': 'false'}",
			msgParm1: 'ssltls',
			msgParm3: "{'tls11': False, 'tls10': True, 'tls13': False, 'tls12': False, 'ssl3': 'false'}",
			deviceId: 'AAJR011000082',
			deviceName: 'C4000i'
		},
		logId: '2388306297970-UvznBWBoT4T3DNpR2G5YvR',
		msgKey: 'deviceSettingRemediated',
		createdAt: '1682602502030',
		SK: '2388306297970-UvznBWBoT4T3DNpR2G5YvR',
		message:
			"Device DeviceName (AAJR011000082) setting ssltls remediated from '{'tls11': 'false', 'tls10': 'true', 'tls13': 'false', 'tls12': 'false', 'ssl3': 'false'}' to '{'tls11': False, 'tls10': True, 'tls13': False, 'tls12': False, 'ssl3': 'false'}'",
		id: 'AAJR011000082',
		PK: 'NR00001551-SGENNFR',
		severity: 'low',
		type: 'device'
	},

	{
		info: {
			tenantId: 'NR00001505-SGENNFR',
			policyId: '2388561135299-81pBUA7sVRhthckVQaEnd1',
			policyItemDiffs: [
				{
					msg: "Policy item rtscan requirement is changed from 'false' to 'true'",
					newSetting: true,
					msgKey: 'rtscanSettingChange',
					oldSetting: false,
					policyItemKey: 'rtscan'
				}
			],
			policyName: 'new policy'
		},
		logId: '2388556831106-Ht16ek8RbphARC4cQETwpK',
		msgKey: 'policyUpdated',
		createdAt: '1682351968894',
		SK: '2388556831106-Ht16ek8RbphARC4cQETwpK',
		message:
			'Policy new policy (2388561135299-81pBUA7sVRhthckVQaEnd1) updated in tenancy NR00001505-SGENNFR license',
		id: '2388561135299-81pBUA7sVRhthckVQaEnd1',
		PK: 'NR00001505-SGENNFR',
		severity: 'low',
		type: 'policy'
	},
	// {
	// 	info: {
	// 		msgParm2:
	// 			'2023-04-23 10:48:26 (UTC) 13008260 AVCORE v2.2 Linux/arm-EABI 11.0.1.19 (March 15, 2021) [E:2023-12-31 00:00:00 (UTC)]',
	// 		msgParm1: 'patternfileversion',
	// 		msgParm3:
	// 			'2023-04-23 19:38:51 (UTC) 13017016 AVCORE v2.2 Linux/arm-EABI 11.0.1.19 (March 15, 2021) [E:2023-12-31 00:00:00 (UTC)]',
	// 		deviceId: 'AA2J011000007',
	// 		deviceName: 'C360i bizhub C360i - the goo one'
	// 	},
	// 	logId: '2388557862279-GewnP82VkDc7J4VU8AZGCh',
	// 	msgKey: 'deviceSettingRemediated',
	// 	createdAt: '1682631545925',
	// 	SK: '2388557862279-GewnP82VkDc7J4VU8AZGCh',
	// 	message:
	// 		"Device DeviceName (AA2J011000007) setting patternfileversion remediated from '2023-04-23 10:48:26 (UTC) 13008260 AVCORE v2.2 Linux/arm-EABI 11.0.1.19 (March 15, 2021) [E:2023-12-31 00:00:00 (UTC)]' to '2023-04-23 19:38:51 (UTC) 13017016 AVCORE v2.2 Linux/arm-EABI 11.0.1.19 (March 15, 2021) [E:2023-12-31 00:00:00 (UTC)]'",
	// 	id: 'AA2J011000007',
	// 	PK: 'NR00001551-SGENNFR',
	// 	severity: 'low',
	// 	type: 'device'
	// },
	{
		SK: 'log-1',
		type: 'device',
		severity: 'low',
		createdAt: 1613659104896,
		// message: 'bizhub 558 passed its policy assessment.',
		msgKey: 'devicePassedAssessment',
		info: {
			deviceId: 'A112233440001',
			deviceName: '558'
		}
	},
	{
		SK: 'log-2',
		type: 'device',
		severity: 'low',
		createdAt: 1613659204896,
		// message: 'bizhub 364e passed its policy assessment.',
		msgKey: 'devicePassedAssessment',
		info: {
			deviceId: 'A61F011000007',
			deviceName: '364e'
		}
	},
	{
		SK: 'log-3',
		type: 'device',
		severity: 'high',
		createdAt: 1613659202896,
		// message: 'bizhub 364e did not pass its policy assessment.',
		msgKey: 'deviceFailedAssessment',
		info: {
			deviceId: 'A61F011000007',
			deviceName: '364e',
			policyFails: [
				{
					msg: "adminpwvalid is 'false' but should be 'true'",
					deviceSetting: false,
					msgKey: 'adminpwvalidFail',
					requiredSetting: true,
					policyItemKey: 'adminpwvalid'
				}
			]
		}
	},
	{
		SK: 'log-4',
		type: 'device',
		severity: 'high',
		createdAt: 1612759304896,
		// message: 'bizhub 364e did not pass its policy assessment.',
		msgKey: 'deviceFailedAssessment',
		info: {
			deviceId: 'A112233440004',
			deviceName: '364e',
			policyFails: [
				{
					msg: "hddencrypt is 'false' but should be 'true'",
					deviceSetting: false,
					msgKey: 'hddencryptFail',
					requiredSetting: 'true',
					policyItemKey: 'hddencrypt'
				},
				{
					msg: "encryptpdf is '120' but should be '5'",
					deviceSetting: 120,
					msgKey: 'encryptpdfFail',
					requiredSetting: '5',
					policyItemKey: 'encryptpdf'
				},
				{
					msg: "idandprint is 'null' but should be '30'",
					deviceSetting: null,
					msgKey: 'idandprintFail',
					requiredSetting: '30',
					policyItemKey: 'idandprint'
				}
			]
		}
	},
	{
		SK: 'log-5',
		type: 'device',
		severity: 'high',
		createdAt: 1612659304896,
		// message: 'bizhub C300 did not pass its policy assessment.',
		msgKey: 'deviceFailedAssessment',
		info: {
			deviceId: 'A112233440005',
			deviceName: 'C300',
			policyFails: [
				{
					msg: "encryptpdf is '120' but should be '15'",
					deviceSetting: 120,
					msgKey: 'encryptpdfFail',
					requiredSetting: '15',
					policyItemKey: 'encryptpdf'
				}
			]
		}
	},
	{
		SK: 'log-6',
		type: 'device',
		severity: 'high',
		createdAt: 1603659104896,
		// message: 'bizhub c558 did not pass its policy assessment.',
		msgKey: 'deviceFailedAssessment',
		info: {
			deviceId: 'A112233440006',
			deviceName: 'c558',
			policyFails: [
				{
					msg: "idandprint is '60`' but should be '30'",
					deviceSetting: 60,
					msgKey: 'idandprintFail',
					requiredSetting: '30',
					policyItemKey: 'idandprint'
				}
			]
		}
	},
	{
		SK: 'log-7',
		type: 'device',
		severity: 'high',
		createdAt: 1603659204896,
		// message: 'bizhub C300i did not pass its policy assessment.',
		msgKey: 'deviceFailedAssessment',
		info: {
			deviceId: 'A112233440007',
			deviceName: 'C300i',
			policyFails: [
				{
					msg: "publicauth is '2' but should be '1'",
					deviceSetting: 2,
					msgKey: 'publicauthFail',
					requiredSetting: '1',
					policyItemKey: 'publicauth'
				}
			]
		}
	},
	{
		SK: 'log-8',
		type: 'device',
		severity: 'low',
		createdAt: 1603659304896,
		// message: 'bizhub 364e passed its policy assessment.',
		msgKey: 'devicePassedAssessment',
		info: {
			deviceId: 'A112233440008',
			deviceName: '364e'
		}
	},
	{
		SK: 'log-9',
		type: 'device',
		severity: 'low',
		createdAt: 1602759304896,
		// message: 'bizhub 558 passed its policy assessment.',
		msgKey: 'devicePassedAssessment',
		info: {
			deviceId: 'A112233440009',
			deviceName: '558'
		}
	},
	{
		SK: 'log-10',
		type: 'device',
		severity: 'low',
		createdAt: 1602659304896,
		// message: 'bizhub C300i passed its policy assessment.',
		msgKey: 'devicePassedAssessment',
		info: {
			deviceId: 'A1122334400010',
			deviceName: 'C300i'
		}
	},
	{
		SK: 'log-11',
		type: 'device',
		severity: 'low',
		createdAt: 1612659304896,
		// message: 'bizhub 558 passed its policy assessment.',
		msgKey: 'devicePassedAssessment',
		info: {
			deviceId: 'A1122334400011',
			deviceName: '558'
		}
	},
	{
		SK: 'log-12',
		type: 'device',
		severity: 'low',
		createdAt: 1593659104896,
		// message: 'bizhub C300i passed its policy assessment.',
		msgKey: 'devicePassedAssessment',
		info: {
			deviceId: 'A1122334400012',
			deviceName: 'C300i'
		}
	},
	{
		SK: 'log-13',
		type: 'device',
		severity: 'low',
		createdAt: 1593659204896,
		// message: 'bizhub 558 passed its policy assessment.',
		msgKey: 'devicePassedAssessment',
		info: {
			deviceId: 'A1122334400013',
			deviceName: '558'
		}
	},
	{
		SK: 'log-12-online',
		type: 'device',
		severity: 'low',
		createdAt: 1593659104846,
		// message: 'bizhub C300i passed its policy assessment.',
		msgKey: 'deviceOnline',
		info: {
			deviceId: 'A1122334400012',
			deviceName: 'C300i'
		}
	},
	{
		SK: 'log-13-online',
		type: 'device',
		severity: 'low',
		createdAt: 1593659204836,
		// message: 'bizhub 558 passed its policy assessment.',
		msgKey: 'deviceOnline',
		info: {
			deviceId: 'A1122334400013',
			deviceName: '558'
		}
	},
	{
		SK: 'log-12-offline',
		type: 'device',
		severity: 'low',
		createdAt: 1593659104826,
		// message: 'bizhub C300i passed its policy assessment.',
		msgKey: 'deviceOffline',
		info: {
			deviceId: 'A1122334400012',
			deviceName: 'C300i'
		}
	},
	{
		SK: 'log-13-offline',
		type: 'device',
		severity: 'low',
		createdAt: 1583659204816,
		// message: 'bizhub 558 passed its policy assessment.',
		msgKey: 'deviceOffline',
		info: {
			deviceId: 'A1122334400013',
			deviceName: '558'
		}
	},
	{
		SK: 'log-14',
		type: 'device',
		severity: 'low',
		createdAt: 1593659304896,
		// message: 'bizhub C300 passed its policy assessment.',
		msgKey: 'taskStatus',
		info: {
			type: 'change-admin-password',
			id: '243000008-7g3uemBjwKNCVuzjes',
			status: 'completed',
			deviceId: 'A1122334400014',
			deviceName: 'C300'
		}
	},
	{
		SK: 'log-15',
		type: 'license',
		severity: 'low',
		createdAt: 1592659304896,
		// message: 'bizhub 558 passed its policy assessment.',
		msgKey: 'licenseSetupForTenancy',
		info: {
			tenantId: 'changeMeId'
		}
	},
	{
		SK: 'log-17',
		type: 'device',
		severity: 'low',
		createdAt: 1613639304896,
		// message: 'bizhub 558 passed its policy assessment.',
		msgKey: 'deviceSettingRemediated',
		info: {
			msgParm1: 'autodoc',
			msgParm2: 'Day3',
			msgParm3: 'Day7',
			deviceId: 'A1122334400017',
			deviceName: '558'
		}
	},
	{
		SK: 'log-19',
		type: 'device',
		severity: 'low',
		createdAt: 1613619304896,
		// message: 'bizhub 558 passed its policy assessment.',
		msgKey: 'deviceSettingRemediated',
		info: {
			msgParm1: 'ssltls',
			msgParm2: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'true', 'tls10': 'false'}",
			msgParm3: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'false', 'tls10': 'false'}",
			deviceId: 'A1122334400019',
			deviceName: 'C300'
		}
	}
];

let mpDevices = [
	{
		id: 'mock-mp-device-guid-1',
		name: 'C558',
		family: 'ZeusSBK',
		generation: 'IT5',
		productLine: 'bizhub',
		localIp: '10.15.212.43',
		serial: 'A61F011400204',
		friendlyName: 'Second Floor - Dev',
		tenantId: undefined,
		publicKey:
			'BAGTc+jfLQ+a4QEy4pqr+T7q08Kjx+8w/bi2kSLGiGn0EdO+fcgQIKlsv6rCo/JrmgiuDdLxwCJ8QtPwWrCB/20blAA7uSGIjiRrinZk/nuzsWsKwpbD/DxFeYDt1r2TEW3ER4gfk4n3zX6dS2QpC9o3DpkQJgWNBZnwi8/2TqvDTGFVIQ=='
	},
	{
		id: 'mock-mp-device-guid-2',
		name: 'C556',
		family: 'ZeusSBK',
		generation: 'IT5',
		productLine: 'bizhub',
		localIp: '10.15.212.58',
		serial: 'A61F0114002047',
		friendlyName: 'Second Floor - QA',
		tenantId: undefined,
		publicKey:
			'BAGTc+jfLQ+a4QEy4pqr+T7q08Kjx+8w/bi2kSLGiGn0EdO+fcgQIKlsv6rCo/JrmgiuDdLxwCJ8QtPwWrCB/20blAA7uSGIjiRrinZk/nuzsWsKwpbD/DxFeYDt1r2TEW3ER4gfk4n3zX6dS2QpC9o3DpkQJgWNBZnwi8/2TqvDTGFVIQ=='
	},
	{
		id: 'mock-mp-device-guid-3',
		name: 'C554',
		family: 'VenusMLK',
		generation: 'IT5',
		localIp: '10.15.212.59',
		productLine: 'bizhub',
		serial: 'A61F011400234',
		friendlyName: 'Second Floor - Lab',
		tenantId: undefined,
		publicKey:
			'BAGTc+jfLQ+a4QEy4pqr+T7q08Kjx+8w/bi2kSLGiGn0EdO+fcgQIKlsv6rCo/JrmgiuDdLxwCJ8QtPwWrCB/20blAA7uSGIjiRrinZk/nuzsWsKwpbD/DxFeYDt1r2TEW3ER4gfk4n3zX6dS2QpC9o3DpkQJgWNBZnwi8/2TqvDTGFVIQ=='
	},
	{
		id: 'mock-mp-device-guid-4',
		name: 'C358',
		family: 'Sparrow',
		generation: 'IT6',
		localIp: '10.15.212.60',
		productLine: 'bizhub',
		serial: 'A31F011400204',
		tenantId: undefined,
		publicKey:
			'BAGTc+jfLQ+a4QEy4pqr+T7q08Kjx+8w/bi2kSLGiGn0EdO+fcgQIKlsv6rCo/JrmgiuDdLxwCJ8QtPwWrCB/20blAA7uSGIjiRrinZk/nuzsWsKwpbD/DxFeYDt1r2TEW3ER4gfk4n3zX6dS2QpC9o3DpkQJgWNBZnwi8/2TqvDTGFVIQ=='
	},
	{
		id: 'mock-mp-device-guid-5',
		name: 'C356',
		family: 'Sparrow',
		generation: 'IT6',
		localIp: '10.15.212.61',
		productLine: 'bizhub',
		serial: 'A31F0114002047',
		tenantId: 'someone-elses-group',
		publicKey:
			'BAGTc+jfLQ+a4QEy4pqr+T7q08Kjx+8w/bi2kSLGiGn0EdO+fcgQIKlsv6rCo/JrmgiuDdLxwCJ8QtPwWrCB/20blAA7uSGIjiRrinZk/nuzsWsKwpbD/DxFeYDt1r2TEW3ER4gfk4n3zX6dS2QpC9o3DpkQJgWNBZnwi8/2TqvDTGFVIQ=='
	},
	{
		id: 'mock-mp-device-guid-6',
		name: 'C354',
		family: 'Sparrow',
		generation: 'IT6',
		productLine: 'bizhub',
		localIp: '10.15.212.62',
		serial: 'A31F011400234',
		tenantId: undefined,
		publicKey:
			'BAGTc+jfLQ+a4QEy4pqr+T7q08Kjx+8w/bi2kSLGiGn0EdO+fcgQIKlsv6rCo/JrmgiuDdLxwCJ8QtPwWrCB/20blAA7uSGIjiRrinZk/nuzsWsKwpbD/DxFeYDt1r2TEW3ER4gfk4n3zX6dS2QpC9o3DpkQJgWNBZnwi8/2TqvDTGFVIQ=='
	},
	{
		id: 'mock-mp-device-guid-7',
		name: 'C666',
		family: 'Sparrow',
		generation: 'IT6',
		productLine: 'bizhub',
		localIp: '10.15.212.63',
		serial: 'A61F011000619',
		tenantId: undefined,
		publicKey:
			'BAGTc+jfLQ+a4QEy4pqr+T7q08Kjx+8w/bi2kSLGiGn0EdO+fcgQIKlsv6rCo/JrmgiuDdLxwCJ8QtPwWrCB/20blAA7uSGIjiRrinZk/nuzsWsKwpbD/DxFeYDt1r2TEW3ER4gfk4n3zX6dS2QpC9o3DpkQJgWNBZnwi8/2TqvDTGFVIQ=='
	}
];

let scheduledReports = [
	{
		SK: 'scheduledReports-1',
		name: 'Mock Scheduled Report 1',
		format: 'csv',
		frequency: 'once',
		reportInfo: {
			dateRange: '30',
			filters: {
				devices: [],
				columns: ['name', 'deviceGroup'],
				events: []
			}
		},
		updatedAt: +new Date('2024-02-15T00:00:00.000Z')
	},
	{
		SK: 'scheduledReports-2',
		name: 'Mock Scheduled Report 2',
		format: 'csv',
		frequency: 'year',
		reportInfo: {
			dateRange: '30',
			filters: {
				devices: [],
				columns: ['serial'],
				events: ['notSecure']
			}
		},
		updatedAt: +new Date('2024-02-16T00:00:00.000Z')
	},
	{
		SK: 'scheduledReports-3',
		name: 'Mock Scheduled Report 3',
		format: 'pdf',
		frequency: 'week',
		reportInfo: {
			dateRange: '7',
			filters: {
				devices: [],
				columns: [],
				events: []
			}
		},
		updatedAt: +new Date('2024-02-17T00:00:00.000Z')
	},
	{
		SK: 'scheduledReports-4',
		name: 'Mock Scheduled Report 4',
		format: 'csv',
		frequency: 'month',
		reportInfo: {
			dateRange: '1',
			filters: {
				devices: {},
				columns: {},
				events: {}
			}
		},
		updatedAt: +new Date('2024-02-18T00:00:00.000Z')
	}
];

let subscriptions = [
	{
		SK: 'mock-report-device-status-alert-subscription-1',
		// logsubId: 'mock-report-device-status-alert-subscription-1',
		userId: profile.id,
		type: 'report-device-status',
		severity: '',
		alertEmail: [profile.email],
		parameters: {
			reportTime: '03:35',
			assessedNotSecure: true,
			assessedOffline: true,
			notAssessed: true
		},
		alertCell: [],
		createdAt: 1592659304896,
		updatedAt: 1592659304896
	},
	{
		SK: 'mock-alert-subscription-1',
		// logsubId: 'mock-alert-subscription-1',
		userId: profile.id,
		type: 'device',
		severity: 'high',
		alertEmail: [profile.email],
		alertCell: [],
		createdAt: 1592659304896,
		updatedAt: 1592659304896
	}
];

// MOCK ENDPOINTS
export const mocking = (instance: AxiosInstance, dontMock: boolean = false) => {
	// if (process.env.NODE_ENV !== 'development' || dontMock) return;

	const mock = new MockAdapter(instance, { delayResponse: 600, onNoMatch: 'passthrough' });

	// COMMENT ME TO A BE A USER
	// mock.onGet('/api/v1/tenant').reply(401, {
	if (localStorage.getItem('mock')) {
		mock.onGet('/api/v1/tenant').reply(() => [200, licenseGroups]);

		mock.onGet(`/api/v1/tenant/${selectedLicenseGroup.SK}`).reply(() => [200, selectedLicenseGroup]);
		mock.onPatch(`/api/v1/tenant/${selectedLicenseGroup.SK}/registration`).reply(() => [200, selectedLicenseGroup]);

		mock.onGet(`api/v1/tenant/five`).reply(200, licenseGroups[1]);

		mock.onPatch(/^\/api\/v1\/tenant\/[^/]+$/).reply(config => {
			const licenseGroupId = config.url!.split('/').pop()!;
			const { name } = JSON.parse(config.data);
			licenseGroups.map(licenseGroup => {
				if (licenseGroup.SK === licenseGroupId) {
					licenseGroup.name = name;
				}
				return licenseGroup;
			});
			return [200, {}];
		});

		mock.onDelete(/^\/api\/v1\/alert\/[^/]+\/unsubscribe\/[^/]+$/).reply(config => {
			const subscriptionId = config.url!.split('/').pop()!;
			subscriptions = subscriptions.filter(subscription => subscription.SK !== subscriptionId);
			return [200, {}];
		});

		mock.onPost('/api/v1/alert/subscribe').reply(config => {
			const { userId, type, severity, alertEmail, alertCell, alertSite } = JSON.parse(config.data);

			const newSubscription = {
				SK: `${Math.random()}`,
				userId,
				type,
				severity,
				alertEmail,
				alertCell,
				alertSite,
				createdAt: Date.now(),
				updatedAt: Date.now()
			};

			subscriptions.push(newSubscription);

			return [200, {}];
		});

		mock.onDelete(/^\/api\/v1\/policy\/[^/]+\/[^/]+$/).reply(config => {
			const policyId = config.url!.split('/').pop()!;
			policies = policies.filter(policy => policy.SK !== policyId);
			return [200, {}];
		});

		mock.onDelete(/^\/api\/v1\/device\/[^/]+\/[^/]+$/).reply(config => {
			const serial = config.url!.split('/').pop()!;
			devices = devices.filter(device => device.SK !== serial);
			return [200, {}];
		});

		mock.onDelete(/^\/api\/v1\/user\/[^/]+\/[^/]+$/).reply(config => {
			const userId = config.url!.split('/').pop()!;
			users = users.filter(user => user.SK !== userId);
			return [200, {}];
		});

		mock.onDelete(/^\/api\/v1\/devicegroup\/[^/]+\/[^/]+$/).reply(config => {
			const deviceGroupId = config.url!.split('/').pop()!;
			deviceGroups = deviceGroups.filter(deviceGroup => deviceGroup.SK !== deviceGroupId);
			return [200, {}];
		});

		mock.onDelete(/^\/api\/v1\/report\/[^/]+\/[^/]+$/).reply(config => {
			const scheduledReportId = config.url!.split('/').pop()!;
			scheduledReports = scheduledReports.filter(scheduledReport => scheduledReport.SK !== scheduledReportId);
			return [200, {}];
		});

		// `api/v1/user/${profileId}`
		mock.onPatch(/^\/api\/v1\/user\/flagvaultreset\/[^\/]+$/).reply(config => {
			const userId = config.url!.split('/').pop()!;
			users = users.map(item => {
				if (item.SK === userId) {
					return { ...item, vaultReset: true };
				}
				return { ...item };
			}) as any;
			return [200, users];
		});
		mock.onPost('/api/v1/policy').reply(config => {
			const { policyName, policyItems, timing } = JSON.parse(config.data);
			const newPolicy = {
				SK: `${Math.random()}`,
				policyName,
				policyItems,
				timing: {
					pingInterval: timing.pingInterval,
					telemetryCheckInterval: timing.telemetryCheckInterval,
					offlineThreshold: timing.offlineThreshold
				},
				policyTs: Date.now()
			};

			policies.push(newPolicy);

			return [200, { policyId: newPolicy.SK }];
		});

		mock.onPost('/api/v1/report').reply(config => {
			const { name, format, frequency, reportInfo } = JSON.parse(config.data);
			const newScheduledReport = {
				SK: `${Math.random()}`,
				name,
				format,
				frequency,
				reportInfo,
				updatedAt: Date.now()
			};

			scheduledReports.push(newScheduledReport);

			return [200, {}];
		});

		mock.onPatch(/^\/api\/v1\/device\/[^/]+$/).reply(config => {
			const data: (
				| {
						deviceId: string;
						policyId: string | null;
						deviceGroupId: never;
				  }
				| {
						deviceId: string;
						policyId: never;
						deviceGroupId: string | null;
				  }
			)[] = JSON.parse(config.data);
			const deviceIds = data.map(({ deviceId }) => deviceId);
			const { deviceGroupId } = data[0];
			const policyId = deviceGroupId
				? deviceGroups.find(deviceGroup => deviceGroup.SK === deviceGroupId)!.policyId ?? null
				: data[0].policyId;

			devices = devices.map(device => {
				if (deviceIds.includes(device.SK)) {
					if ((device.policyId ?? null) !== policyId) {
						device.assessmentTs = undefined;
					}
					device.policyId = policyId ?? undefined;
					device.deviceGroupId = deviceGroupId ?? device.deviceGroupId;
					if (device.onlineStatus) {
						// mock set the devices to "secure" after 60 seconds
						setTimeout(() => {
							device.secureStatus = 'pass';
							device.assessmentTs = Date.now();
							// *if* we want to "wake up" devices these line will do it - but the previous `if (device.onlineStatus)` is currently preventing this
							device.onlineStatus = true;
							device.onlineStatusTs = Date.now();
						}, 60_000);
					}
				}
				return device;
			});

			return [200, {}];
		});

		mock.onPatch(/^\/api\/v1\/user\/[^/]+$/).reply(config => {
			const data: { userId: string; permissions: User['permissions'] }[] = JSON.parse(config.data);
			const userIds = data.map(({ userId }) => userId);
			const { permissions } = data[0];

			users = users.map(user => {
				if (userIds.includes(user.SK)) {
					user.permissions = permissions;
				}
				return user;
			});

			return [200, {}];
		});

		mock.onPost('/api/v1/device').reply(config => {
			const data = JSON.parse(config.data);

			const newDevices = data.map((device: (typeof devices)[0]) => ({
				// @ts-ignore
				SK: device.deviceId,
				name: device.name,
				deviceInfo: {
					deviceType: {
						family: device.deviceInfo.deviceType.family
					},
					serialNumber: device.deviceInfo.serialNumber
				},
				friendlyName: device.friendlyName,
				secureStatus: undefined,
				policyId: undefined,
				onlineStatus: true,
				onlineStatusTs: Date.now(),
				active: true,
				deviceGroupId: undefined,
				createdAt: Date.now(),
				assessmentTs: undefined
			}));
			devices.push(...newDevices);

			mpDevices = mpDevices.filter(
				mpDevice => !newDevices.find((newDevice: any) => newDevice.SK === mpDevice.serial)
			);

			return [200, {}];
		});

		mock.onPost('/api/v1/invitation').reply(200, {
			invitationId: 'mock-invitation-id'
		});

		mock.onPost('/api/v1/invitation/mock-invitation-id/link').reply(200, {
			transactionId: 'mock-transaction-id'
		});

		mock.onGet('https://static.getpersonalize.com/geolocation').reply(200, 'US');

		mock.onGet('/api/mp/catalog/pricing?region=US').reply(200, {
			region: 'US',
			currencyCode: 'USD',
			pricing: {
				// '009EC905-B759-41E8-943E-C9B6913E18F8': {
				// 	publicId: '009EC905-B759-41E8-943E-C9B6913E18F8',
				// 	name: 'Personalize Service - Starter Plan',
				// 	showPrice: true,
				// 	priceRanges: [
				// 		{
				// 			sapId: 'PS001M1',
				// 			amount: 0,
				// 			min: 1
				// 		}
				// 	],
				// 	billing: {
				// 		name: 'One Time Fee',
				// 		uid: 'bonce',
				// 		duration: 0,
				// 		months: false,
				// 		gracePeriod: 0
				// 	}
				// },
				[PublicId.StarterMonthly]: {
					publicId: PublicId.StarterMonthly,
					name: 'Shield Guard Service - Starter Plan (per device, monthly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA11', amount: 2.5, min: 1 },
						{ sapId: 'SGAAA12', amount: 1.5, min: 11 },
						{ sapId: 'SGAAA13', amount: 1.3, min: 50 },
						{ sapId: 'SGAAA14', amount: 1.1, min: 250 },
						{ sapId: 'SGAAA15', amount: 0.8, min: 1000 }
					],
					billing: {
						name: 'Monthly',
						uid: 'bmonth',
						duration: 1,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.BusinessMonthly]: {
					publicId: PublicId.BusinessMonthly,
					name: 'Shield Guard Service - Business Plan (per device, monthly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA06', amount: 2.75, min: 1 },
						{ sapId: 'SGAAA07', amount: 1.65, min: 11 },
						{ sapId: 'SGAAA08', amount: 1.43, min: 50 },
						{ sapId: 'SGAAA09', amount: 1.21, min: 250 },
						{ sapId: 'SGAAA10', amount: 0.88, min: 1000 }
					],
					billing: {
						name: 'Monthly',
						uid: 'bmonth',
						duration: 1,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.EnterpriseMonthly]: {
					publicId: PublicId.EnterpriseMonthly,
					name: 'Shield Guard Service - Enterprise Plan (per device, monthly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA01', amount: 3, min: 1 },
						{ sapId: 'SGAAA02', amount: 1.8, min: 11 },
						{ sapId: 'SGAAA03', amount: 1.56, min: 50 },
						{ sapId: 'SGAAA04', amount: 1.32, min: 250 },
						{ sapId: 'SGAAA05', amount: 0.96, min: 1000 }
					],
					billing: {
						name: 'Monthly',
						uid: 'bmonth',
						duration: 1,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.StarterYearlyTerm]: {
					publicId: PublicId.StarterYearlyTerm,
					name: 'Shield Guard Service - Starter Plan (per device, 1-year term)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA16', amount: 42, min: 1 },
						{ sapId: 'SGAAA17', amount: 30, min: 11 },
						{ sapId: 'SGAAA18', amount: 27, min: 50 },
						{ sapId: 'SGAAA19', amount: 25.2, min: 250 },
						{ sapId: 'SGAAA20', amount: 21.6, min: 1000 }
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.BusinessYearlyTerm]: {
					publicId: PublicId.BusinessYearlyTerm,
					name: 'Shield Guard Service - Business Plan (per device, 1-year term)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA21', amount: 45, min: 1 },
						{ sapId: 'SGAAA22', amount: 31.8, min: 11 },
						{ sapId: 'SGAAA23', amount: 29.16, min: 50 },
						{ sapId: 'SGAAA24', amount: 26.52, min: 250 },
						{ sapId: 'SGAAA25', amount: 22.56, min: 1000 }
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.EnterpriseYearlyTerm]: {
					publicId: PublicId.EnterpriseYearlyTerm,
					name: 'Shield Guard Service - Enterprise Plan (per device, 1-year term)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA26', amount: 48, min: 1 },
						{ sapId: 'SGAAA27', amount: 33.6, min: 11 },
						{ sapId: 'SGAAA28', amount: 30.72, min: 50 },
						{ sapId: 'SGAAA29', amount: 27.84, min: 250 },
						{ sapId: 'SGAAA30', amount: 23.52, min: 1000 }
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.StarterYearly]: {
					publicId: PublicId.StarterYearly,
					name: 'Shield Guard Service - Starter Plan (per device, yearly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'FAKEA16', amount: 42, min: 1 },
						{ sapId: 'FAKEA17', amount: 30, min: 11 },
						{ sapId: 'FAKEA18', amount: 27, min: 50 },
						{ sapId: 'FAKEA19', amount: 25.2, min: 250 },
						{ sapId: 'FAKEA20', amount: 21.6, min: 1000 }
					],
					billing: {
						name: 'Yearly',
						uid: 'byear',
						duration: 12,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 100,
						step: 1,
						lock: false
					}
				},
				[PublicId.BusinessYearly]: {
					publicId: PublicId.BusinessYearly,
					name: 'Shield Guard Service - Business Plan (per device, yearly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'FAKEA21', amount: 45, min: 1 },
						{ sapId: 'FAKEA22', amount: 31.8, min: 11 },
						{ sapId: 'FAKEA23', amount: 29.16, min: 50 },
						{ sapId: 'FAKEA24', amount: 26.52, min: 250 },
						{ sapId: 'FAKEA25', amount: 22.56, min: 1000 }
					],
					billing: {
						name: 'Yearly',
						uid: 'byear',
						duration: 12,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 100,
						step: 1,
						lock: false
					}
				},
				[PublicId.EnterpriseYearly]: {
					publicId: PublicId.EnterpriseYearly,
					name: 'Shield Guard Service - Enterprise Plan (per device, yearly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'FAKEA26', amount: 48, min: 1 },
						{ sapId: 'FAKEA27', amount: 33.6, min: 11 },
						{ sapId: 'FAKEA28', amount: 30.72, min: 50 },
						{ sapId: 'FAKEA29', amount: 27.84, min: 250 },
						{ sapId: 'FAKEA30', amount: 23.52, min: 1000 }
					],
					billing: {
						name: 'Yearly',
						uid: 'byear',
						duration: 12,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 100,
						step: 1,
						lock: false
					}
				}
				// [PublicId.DeviceApp]: {
				// 	publicId: PublicId.DeviceApp,
				// 	name: 'Shield Guard Agent',
				// 	showPrice: true,
				// 	priceRanges: [
				// 		{
				// 			sapId: 'moar',
				// 			amount: 0,
				// 			min: 1
				// 		}
				// 	],
				// 	billing: {
				// 		name: 'One Time Fee',
				// 		uid: 'bonce',
				// 		duration: 0,
				// 		months: false,
				// 		gracePeriod: 0
				// 	},
				// 	counter: {
				// 		min: 1,
				// 		max: 1,
				// 		step: 1,
				// 		lock: true
				// 	}
				// }
			}
		});

		mock.onGet('/api/password-management/transaction/user').reply(() => [200, []]);
		mock.onGet('/api/password-management/vault-url/group').reply(200, {});
		mock.onGet('/api/password-management/transaction/group').reply(200, {});
		mock.onPost('/api/password-management/vault').reply(201);
	}

	const mockRegion = localStorage.getItem('mockRegion');
	if (mockRegion) {
		mock.onGet('https://static.getpersonalize.com/geolocation').reply(() => [200, mockRegion]);
	}

	mock.onGet('/api/v1/user/changeMeId').reply(() => [200, users]);
	mock.onGet('/api/v1/user/five').reply(() => [200, XYZUsers]);
	mock.onPatch(`/api/v1/user/${profile.id}`).reply(() => [200, users]);

	mock.onGet('/api/v1/pending-user/changeMeId').reply(() => [200, pendingUsers]);

	mock.onGet('/api/v1/device/changeMeId').reply(() => [200, devices]);
	mock.onGet('/api/v1/device/five').reply(() => [200, devices]);

	mock.onGet('/api/v1/devicegroup/changeMeId').reply(() => [200, deviceGroups]);
	mock.onGet('/api/v1/devicegroup/five').reply(() => [200, deviceGroups]);

	mock.onGet('/api/v1/policy/changeMeId').reply(() => [200, policies]);
	mock.onGet('/api/v1/policy/five').reply(() => [200, policies]);

	mock.onGet('/api/v1/report/changeMeId').reply(() => [200, scheduledReports]);

	if (localStorage.getItem('mock') || localStorage.getItem('mockLogs')) {
		mock.onGet(/\/api\/v1\/log\/[^/]+/).reply(() => [200, logs]);
	}

	if (localStorage.getItem('mockUser')) {
		mock.onGet('/api/sso/session').reply(() => [
			200,
			{
				user: profile,
				vaultUrl: `${process.env.PUBLIC_URL}/mocking/mock-user-vault`
			}
		]);
		// mock.onGet('/api/sso/session').reply(401, null);
	}

	// mock.onGet('/api/v1/user/changeMeId/mock-user-id/devices').reply(200, [
	mock.onGet(/\/api\/v1\/user\/changeMeId\/[^/]+\/devices/).reply(() => [200, mpDevices]);
	mock.onGet(/\/api\/v1\/user\/five\/[^/]+\/devices/).reply(() => [200, mpDevices]);

	// api/v1/alert
	mock.onGet(/\/api\/v1\/alert\/changeMeId\/user\/[^/]+\/subscriptions/).reply(() => [200, subscriptions]);

	// mock.onPatch('/api/license-groups/changeMeId/users').reply(200, {
	// 	errors: {
	// 		add: [
	// 			{ type: 'already-associated', data: { email: 'fake1@email.com' } },
	// 			{ type: 'already-associated', data: { email: 'fake2@email.com' } }
	// 		],
	// 		delete: []
	// 	}
	// });
	mock.onPatch('/api/license-groups/changeMeId/users').reply(200, { errors: { add: [], delete: [] } });
	mock.onPatch('/api/license-groups/changeMeId/devices').reply(200, { success: 'true' });
	mock.onPatch('/api/license-groups/changeMeId/settings').reply(200, { success: 'true' });
	// mock.onPatch('/api/license-groups/changeMeId/settings').reply(401, { success: 'true' });
	// mock.onPatch('/api/license-groups/changeMeId/settings').reply(200, {
	// 	errors: [
	// 		{
	// 			output: {
	// 				statusCode: 400
	// 			}
	// 		}
	// 	]
	// });

	mock.onGet(`/api/mp/catalog/pricing/${PublicId.EnterpriseMonthly}?license_group=changeMeId`).reply(200, {
		total: 140.5,
		currencyCode: 'USD'
	});
	mock.onGet(`/api/mp/catalog/pricing/${PublicId.EnterpriseMonthly}?license_group=changeMeId&quantity=100`).reply(
		200,
		{
			total: 180.5,
			currencyCode: 'USD'
		}
	);

	mock.onGet('/api/user/functions').reply(200, {
		static: {
			w00000001: { name: 'Copy', class: 'icn-copy' },
			w00000002: { name: 'Scan To Email', class: 'icn-email' },
			w00000003: { name: 'Scan To Box', class: 'icn-box' },
			w00000004: { name: 'Scan To PC', class: 'icn-scanpc' },
			w00000005: { name: 'Internet Fax', class: 'icn-inetfax' },
			w00000006: { name: 'IP Address Fax', class: 'icn-ipfax' },
			w00000007: { name: 'Fax', class: 'icn-fax' },
			w00000016: { name: 'IP Fax (SIP)', class: 'icn-ipaddrfax' },
			w00000017: { name: 'Quick Copy', class: 'icn-quick-copy' },
			w00000101: { name: 'Web Browser', class: 'icn-web' },
			w00000102: {
				name: 'MarketPlace',
				class: 'icn-mpinstall'
			}, // pre-install.
			w00000201: { name: 'Address Book', class: 'icn-address' },
			w00000202: { name: 'USB Mem. Print', class: 'icn-usb' },
			w00000203: { name: 'Classic Style', class: 'icn-classic' },
			w00000204: { name: 'Classic Copy', class: 'icn-classic-copy' },
			w00000205: { name: 'Classic Scan', class: 'icn-classic-scan' },
			w00000206: { name: 'Classic Fax', class: 'icn-classic-fax' },
			w00000207: { name: 'Box', class: 'icn-classic-box' },
			w00000208: { name: 'Serverless Pull Printing', skip: true },
			w00000301: { name: 'ID & Print', class: 'icn-idprint' },
			w00000302: { name: 'Paper', class: 'icn-paper' },
			w00000303: { name: 'Card Shot', class: 'icn-idcard' },
			w00000304: { name: 'Scan to Me', class: 'icn-scanme' },
			w00000401: { name: 'APP (OpenAPI)', class: 'icn-app' }
		}
	});

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&id=changeMeId').reply(200, [
		{ device_name: 'Alpha Ronald 100', device_id: '13fhhi70ehf', users_using_ct: '11230', auth_count: '3133' },
		{ device_name: 'Bravo', device_id: '241eih11499', users_using_ct: '1340', auth_count: '3203' },
		{ device_name: 'Charlie', device_id: '30jgij44b5d', users_using_ct: '140', auth_count: '3138' },
		{ device_name: 'Delta', device_id: '4fcech302hc', users_using_ct: '104', auth_count: '3281' },
		{ device_name: 'Elos', device_id: '5fifbha1e53', users_using_ct: '1044', auth_count: '3295' }
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=device&id=changeMeId').reply(200, [
		{ device_name: 'Alpha Ronald 10', device_id: '13fhhi70ehf', users_using_ct: '11230', auth_count: '3133' },
		{ device_name: 'Bravo', device_id: '241eih11499', users_using_ct: '1340', auth_count: '3203' },
		{ device_name: 'Charlie', device_id: '30jgij44b5d', users_using_ct: '140', auth_count: '3138' },
		{ device_name: 'Delta', device_id: '4fcech302hc', users_using_ct: '104', auth_count: '3281' },
		{ device_name: 'Elos', device_id: '5fifbha1e53', users_using_ct: '1044', auth_count: '3295' }
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=user&id=changeMeId').reply(200, [
		{
			user_name: 'alice',
			user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '005',
			auth_count: '1604'
		},
		{
			user_name: 'ben',
			user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '123125',
			auth_count: '1659'
		},
		{
			user_name: 'casay',
			user_id: '003b5d80ahjg08815gif5290901i1aac5bdhf3d7',
			devices_used_ct: '655',
			auth_count: '1545'
		},
		{
			user_name: 'derek',
			user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '45',
			auth_count: '1516'
		},
		{
			user_name: 'evan',
			user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '335',
			auth_count: '1639'
		},
		{
			user_name: 'frank',
			user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '15',
			auth_count: '1578'
		},
		{
			user_name: 'george',
			user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '25',
			auth_count: '1635'
		},
		{
			user_name: 'henry',
			user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '56',
			auth_count: '1585'
		},
		{
			user_name: 'issac',
			user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '54',
			auth_count: '1639'
		},
		{
			user_name: 'jerry',
			user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '51',
			auth_count: '1650'
		}
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=user&id=changeMeId').reply(200, [
		{
			user_name: 'alice1',
			user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '05',
			auth_count: '164'
		},
		{
			user_name: 'ben2',
			user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '1659'
		},
		{
			user_name: 'casay',
			user_id: '003b5d80ahjg08815gif5290901i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '155'
		},
		{
			user_name: 'derek1',
			user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '16'
		},
		{
			user_name: 'evan',
			user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '5'
		},
		{
			user_name: 'frank',
			user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '15',
			auth_count: '78'
		},
		{
			user_name: 'george',
			user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '25',
			auth_count: '35'
		},
		{
			user_name: 'henry',
			user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '56',
			auth_count: '1585'
		},
		{
			user_name: 'issac',
			user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '54',
			auth_count: '1639'
		},
		{
			user_name: 'jerry',
			user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '51',
			auth_count: '1650'
		}
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=user&by=week&id=changeMeId').reply(
		200,
		[
			{
				user_name: 'alice',
				user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '14',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'alice',
				user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '12',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'ben',
				user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '19',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'ben',
				user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'casay',
				user_id: '003b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '131',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'casay',
				user_id: '003b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '14',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'derek',
				user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '16',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'derek',
				user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'evan',
				user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '11',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'evan',
				user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '18',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'frank',
				user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '46',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'frank',
				user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '32',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'george',
				user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '25',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'george',
				user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'henry',
				user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '186',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'henry',
				user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '9',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'issac',
				user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '12',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'issac',
				user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '17',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'jerry',
				user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '32',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'jerry',
				user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '1',
				by_week: '2020-10-26T00:00:00.000Z'
			}
		]
	);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2883',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '250',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2976',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '227',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2899',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '239',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3043',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '238',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Elos',
			device_id: '5fifbha1e53',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3049',
			by_week: '2020-10-19T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=device&by=day&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '283',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '22',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '29',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '22',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '1',
			users_using_ct: '1',
			auth_count: '28',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '23',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3043',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '238',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Elos',
			device_id: '5fifbha1e53',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3049',
			by_day: '2020-10-19T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&devicefilt=13fhhi70ehf&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2883',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '250',
			by_week: '2020-10-26T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&devicefilt=241eih11499&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '14',
			users_using_ct: '11',
			auth_count: '83',
			by_week: '2020-10-01T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '13',
			auth_count: '250',
			by_week: '2020-10-0500:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '33',
			auth_count: '20',
			by_week: '2020-10-12T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '23',
			auth_count: '10',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '3',
			auth_count: '52',
			by_week: '2020-10-26T00:00:00.000Z'
		}
	]);

	mock.onGet('https://temporary-vault-url.com/mockId').reply(200, {});

	if (localStorage.getItem('mock')) {
		mock.onGet('/api/v1/vault/appManage').reply(201, {
			PlaintextKey: 'hWoD6CfRlaG8SM7ICEIWAWXCkDbS+O2mymAAcuSE7yw=',
			EncryptedKey:
				'AQIDAHis7WPsTvExA7cMu6Qy66tpSGoDDKFsQfNjt7BUUqZe8AEypE0//4WVa08f3qRMPYiIAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMYiqGYDGaTefjbeNAAgEQgDtlhTgVqFDaIg2RW6xPw5lNPjszkcpL7381/oNXchH1Syhlz3+0uuDLi1XPETeawGrOxHrEop6uM4Et6w=='
		});
		mock.onGet('/api/v1/vault/appManage/mock-user-id').reply(201, {
			password: 'pass1234'
		});
		mock.onPatch('/api/v1/tenant/changeMeId').reply(200, {});

		mock.onPost('/api/password-management/vault').reply(() => {
			if (localStorage.getItem('mockAppManaged')) {
				localStorage.removeItem('mockAppManaged');
				return [201, { vaultType: 'self' }];
			}
			localStorage.setItem('mockAppManaged', 'true');
			return [201, { vaultType: 'app' }];
		});

		mock.onGet('/api/v1/vault/type/mock-user-id').reply(() => {
			return [200, localStorage.getItem('mockAppManaged') ? { vaultType: 'app' } : { vaultType: 'self' }];
		});
		// mock.onGet('/api/v1/vault/type/mock-user-id').reply(404,{})
	}
};
