import localForage from 'localforage';
import _ from '@lodash';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { ScheduledReportsState, ScheduledReportsActionTypes } from 'app/store/types';

const initialState: ScheduledReportsState = {};

const scheduledReportsReducer = (state = initialState, action: ScheduledReportsActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { scheduledReports }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: scheduledReports
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'scheduledReports',
		storage: localForage
	},
	scheduledReportsReducer
);

// Selectors
export const getScheduledReportsById = ({ app: { selectedLicenseGroupId }, scheduledReports }: RootState) =>
	scheduledReports[selectedLicenseGroupId].byId;
