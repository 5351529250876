import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import LicenseGroupsMenu from 'app/fuse-layouts/shared-components/LicenseGroupsMenu';
import Notification from 'app/components/Notification';
import RecoveryNotification from 'app/components/RecoveryNotification';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'app/modules/react-redux';
import { getProfile, getSelectedLicenseGroupData, getLicenseGroups, getHideNavbar } from 'app/store/reducers';
import { useTranslation } from 'react-i18next';




declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}




declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}



declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const useStyles = makeStyles(theme => ({
	pricingButton: {
		color: '#FFFFFF',
		backgroundColor: '#71B93B',
		'&:hover': {
			backgroundColor: '#65A635'
		}
	},
	separator: {
		width: 1,
		height: 64,
		backgroundColor: theme.palette.divider
	}
}));

function ToolbarLayout1({ adminPage = false }: { adminPage: boolean }) {
	const { t } = useTranslation();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);
	const location = useLocation();

	const hideNavbar = useSelector(getHideNavbar);

	const licenseGroups = useSelector(getLicenseGroups) as ReturnType<typeof getLicenseGroups> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app
	const licenseGroupData = useSelector(getSelectedLicenseGroupData) as
		| ReturnType<typeof getSelectedLicenseGroupData>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const profile = useSelector(getProfile) as ReturnType<typeof getProfile> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const classes = useStyles();

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={toolbarTheme}>
                <AppBar id="fuse-toolbar" className="flex relative z-10" color="secondary">
                    <Toolbar className="p-0">
                        {adminPage && config.navbar.display && config.navbar.position === 'left' && !hideNavbar && (
                            <Hidden lgUp>
                                <NavbarMobileToggleButton className="w-64 h-64 p-0" />
                                <div className={classes.separator} />
                            </Hidden>
                        )}

                        {!(adminPage && config.navbar.display && !hideNavbar) && (
                            <div className="flex justify-start">
                                <div className="flex flex-shrink-0 items-center pl-12">
                                    <Logo link />
                                </div>
                            </div>
                        )}

                        {/* TODO::can we add a way to get to the groups page without forgetting your selected group? */}
                        {adminPage && licenseGroupData && licenseGroups && licenseGroups.length > 1 && (
                            <div className="flex items-center">
                                <LicenseGroupsMenu />
                            </div>
                        )}

                        {/* HACK-ish::use pathname to show button only on welcome page */}
                        {location.pathname === '/' && (
                            <div className="flex absolute-center justify-center">
                                <Link to="/pricing" role="button">
                                    <Button className={clsx(classes.pricingButton, 'text-16')} variant="contained">
                                        {t('Choose your plan')}
                                    </Button>
                                </Link>
                            </div>
                        )}

                        <div className="flex ml-auto justify-end">
                            {profile ? (
                                <>
                                    {adminPage && licenseGroupData && licenseGroupData.dataLoaded && <Notification />}
                                    {adminPage && licenseGroupData && licenseGroupData.dataLoaded && <RecoveryNotification/>}
                                    <UserMenu adminPage={adminPage} />
                                </>
                            ) : (
                                <Button
                                    className="h-64 text-white"
                                    href={`/api/sso/login?redirect=${encodeURIComponent(location.pathname + location.search + location.hash)}`}
                                    role="button"
                                >
                                    <Icon className="text-green-accent">lock</Icon>

                                    <div className="mx-12">
                                        <Typography component="span" className="font-600 flex">
                                            {t('Login')}
                                        </Typography>
                                    </div>
                                </Button>
                            )}

                            {/* <div className={classes.separator} />

                            <LanguageSwitcher />

                            <Hidden mdDown>
                                <div className={classes.separator} />
                            </Hidden> */}
                        </div>

                        {adminPage && config.navbar.display && config.navbar.position === 'right' && !hideNavbar && (
                            <Hidden lgUp>
                                <NavbarMobileToggleButton />
                            </Hidden>
                        )}
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default React.memo(ToolbarLayout1);
