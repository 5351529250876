import type { PublicId } from './market.types';
import type { PasswordConfig, PolicyItem } from './policies.types';
import type { VaultType } from './profile.types';

export type User = {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	vaultReset: boolean;
	dateAdded: number;
	vaultType: VaultType;
	permissions: { [permission: string]: boolean | undefined };
};

export type PendingUser = {
	email: string;
	token: string;
	dateAdded: number;
	pending: boolean;
};

export type Policy = {
	id: string;
	name: string;
	policyItems: PolicyItem[];
	pingInterval: number;
	telemetryCheckInterval: number;
	offlineThreshold: number;
	lastModified: Date;
	passwordConfig: PasswordConfig;
};

export type PolicyFails = {
	// msg: string;
	msgKey: string;
	deviceSetting: PolicyItem['requirement'];
	requiredSetting: PolicyItem['requirement'];
	policyItemKey: string;
	notRemediable?: boolean;
	noApiAvailable?: boolean;
	cantDisableUnsupportedFeature?: boolean;
	upgradeFirmware?: boolean;
	patternFilePending?: boolean;
};

export type Device = {
	serial: string; // TODO::change this to `id` (user `serialNumber` if actually comparing serial numbers)
	serialNumber: string;
	name: string;
	generation: 'A4' | 'IT3' | 'IT4' | 'IT5' | 'IT6' | 'workplacehub';
	active: boolean;
	friendlyName?: string;
	dateAdded: number;
	localIp?: string;
	lastAssessment?: number;
	deviceGroupId: string | undefined;
	policyId: Policy['id'] | undefined;
	secureStatus: 'pass' | 'fail' | undefined;
	deviceStatus: 'secure' | 'notSecure' | 'noPolicy' | 'notAssessed' | 'pending' | 'offline';
	pending: boolean;
	offline: boolean;
	lastOnlineStatusChange: number | undefined;
	lastSecure: number | undefined; // `lastSecure` is based on different timestamps depending on *why* it's "not secure"
	publicKey?: string;
	policyFails?: PolicyFails[];
};

export type DeviceGroup = {
	id: string;
	name: string;
	policyId: Policy['id'] | undefined;
	dateUpdated: number;
};

export type Log = {
	id: string;
	type: 'device' | 'policy' | 'user';
	dateCreated: number;
	severity: 'high' | 'low';
	messageKey: string;
	info?: {
		[key: string]: string | undefined;
	} & {
		policyFails?: PolicyFails[];
	};
};

export const dateRangeOptions = ['days30', 'days7', 'yesterday', 'today', 'custom'] as const;
export const exportReportFormatOptions = ['csv'] as const;
export const exportReportFrequencyOptions = ['once', 'week', 'month', 'year'] as const;

export type ScheduledReport = {
	id: string;
	name: string;
	format: (typeof exportReportFormatOptions)[number];
	frequency: (typeof exportReportFrequencyOptions)[number];
	reportInfo: {
		dateRange: (typeof dateRangeOptions)[number];
		fromDate?: number;
		toDate?: number;
		filters: {
			[key: string]: string[];
		};
	};
	lastModified: number;
};

export type LicenseGroupData = {
	id: string;
	name: string;
	ownerId?: User['id'];
	quantity: number;
	capacity: number;
	version: number;
	currentUsers: number;
	currentUserLostVaultKey?: boolean;
	createdAt?: number;
	expirationDate?: number;
	catalogPublicId: PublicId;
	orderType: 'PRODUCTION' | 'DEMO' | 'NFR';
	orderNumber: string;
	permissions: { [permission: string]: boolean | undefined };
	// HACK-ish::add prop to say additional data has loaded
	dataLoaded?: boolean;
	isSelfManagedRequired: boolean;
	tenantUpdatedAt: string;
};

export type LicenseGroupsState = {
	byId: {
		[licenseGroupId: string]: LicenseGroupData;
	};
};

export type GetLicenseGroupsSuccessAction = {
	type: 'GET_LICENSE_GROUPS_SUCCESS';
	payload: {
		data: {
			[licenseGroupId: string]: LicenseGroupData; // HACK-ish: this is actually a partial of this data (only on the GroupsView page)
		};
	};
};

export type GetLicenseGroupDataSuccessAction = {
	type: 'GET_LICENSE_GROUP_DATA_SUCCESS';
	payload: {
		licenseGroupId: string;
		data: {
			details: LicenseGroupData;
			users: {
				[userId: string]: User;
			};
			pendingUsers: {
				[email: string]: PendingUser;
			};
			devices: {
				[serial: string]: Device;
			};
			deviceGroups: {
				[deviceGroupId: string]: DeviceGroup;
			};
			policies: {
				[policyId: string]: Policy;
			};
			logs: {
				[logId: string]: Log;
			};
			scheduledReports: {
				[scheduledReportId: string]: ScheduledReport;
			};
		};
	};
};

export type LoggedOutUserAction = {
	type: 'LOGGED_OUT_USER';
};
export type PurgeStateAction = {
	type: 'PURGE_STATE';
};

export type LicenseGroupsActionTypes =
	| GetLicenseGroupsSuccessAction
	| GetLicenseGroupDataSuccessAction
	| LoggedOutUserAction
	| PurgeStateAction;
