import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import React from 'react';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import { Link, useLocation } from 'react-router-dom';
import * as Actions from 'app/store/actions';
import { getProfile, getSelectedLicenseGroupData, getLicenseGroups, getHideNavbar } from 'app/store/reducers';
import { useTranslation } from 'react-i18next';




declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}




declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}



declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


// import LanguageSwitcher from '../../shared-components/LanguageSwitcher';

const useStyles = makeStyles(theme => ({
	separator: {
		width: 1,
		height: 64,
		backgroundColor: theme.palette.divider
	}
}));

function ToolbarLayoutAdmin({ adminPage = false }: { adminPage: boolean }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);
	const location = useLocation();

	const hideNavbar = useSelector(getHideNavbar);

	const licenseGroups = useSelector(getLicenseGroups) as ReturnType<typeof getLicenseGroups> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app
	const licenseGroupData = useSelector(getSelectedLicenseGroupData) as
		| ReturnType<typeof getSelectedLicenseGroupData>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const profile = useSelector(getProfile) as ReturnType<typeof getProfile> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const classes = useStyles();

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={toolbarTheme}>
                <AppBar
                    id="fuse-toolbar"
                    className="flex relative z-10"
                    color="default"
                    style={{ backgroundColor: toolbarTheme.palette.background.default }}
                >
                    <Toolbar className="container p-0 lg:px-24">
                        {config.navbar.display && !hideNavbar && (
                            <Hidden mdUp>
                                <NavbarMobileToggleButton className="w-64 h-64 p-0" />
                                <div className={classes.separator} />
                            </Hidden>
                        )}

                        {/* TODO::can we add a way to get to the groups page without forgetting your selected group? */}
                        <div className={clsx('flex flex-1 items-center')}>
                            {adminPage && licenseGroupData && licenseGroups && licenseGroups.length > 1 && (
                                <Button
                                    className="h-64 normal-case"
                                    onClick={() => dispatch(Actions.setSelectedLicenseGroup(undefined))}
                                >
                                    <Icon>business</Icon>

                                    <div className="flex-col mx-12 items-start">
                                        <Typography component="span" className="normal-case font-600 flex">
                                            {licenseGroupData.name}
                                        </Typography>
                                    </div>

                                    <Icon className="text-16">unfold_more</Icon>
                                </Button>
                            )}
                        </div>

                        <Hidden xlDown>
                            <div className="flex flex-1 justify-center">
                                <Link to={adminPage ? '/admin/dashboard' : '/'}>
                                    <div className={clsx('flex flex-shrink-0 items-center')}>
                                        <Logo />
                                    </div>
                                </Link>
                            </div>
                        </Hidden>

                        <div className="flex flex-1 justify-end">
                            {profile ? (
                                <UserMenu adminPage={adminPage} />
                            ) : (
                                <Button
                                    className="h-64"
                                    href={`/api/sso/login?redirect=${encodeURIComponent(location.pathname + location.search + location.hash)}`}
                                    role="button"
                                >
                                    <Icon>lock</Icon>

                                    <div className="mx-12">
                                        <Typography component="span" className="font-600 flex">
                                            {t('Login')}
                                        </Typography>
                                    </div>
                                </Button>
                            )}

                            {/* <div className={classes.separator} />

                            <LanguageSwitcher />

                            <Hidden mdDown>
                                <div className={classes.separator} />
                            </Hidden> */}
                        </div>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default React.memo(ToolbarLayoutAdmin);
