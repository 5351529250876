import axios from 'app/client';
import { AxiosError } from 'axios';
import _ from '@lodash';
import { responseError, responseErrors } from 'app/utils/helpers';
import { reverseParseJsonHack, enrichPolicyItemsHack } from 'app/utils/hacks';
import {
	getDevices,
	getSelectedLicenseGroupId,
	getUserVault,
	getPermissionCheck,
	getPasswordConfigByPolicyId,
	getSelectedLicenseGroupData
} from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { Policy, PolicyItem, PasswordConfig, PolicyItemSetting } from 'app/store/types';
import { Crypto, Util } from '@sec/shield-guard-password-management';
import { remediatablePolicyItems } from 'app/main/policies/allPolicyItems';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';
import * as devicesActions from './devices.actions';

export const submitPasswordChange = ({
	policyId,
	passwordConfig,
	manualPasswordValue
}: {
	policyId: Policy['id'];
	passwordConfig: PasswordConfig;
	manualPasswordValue?: string;
}): AppThunk => async (dispatch, getState) => {
	const defaultInterval = 50 * 365 * 24 * 60 * 60; // HACK::FIXME::add a defaultInterval of 50 years until API supports no interval at all
	let data;
	if (passwordConfig.passwordGenerationType === 'random') {
		data = {
			type: 'random',
			policyId,
			interval: passwordConfig.rotationDuration ?? defaultInterval
		};
	} else if (passwordConfig.passwordGenerationType === 'manual' && !_.isEmpty(manualPasswordValue)) {
		data = {
			type: 'custom',
			policyId,
			password: manualPasswordValue
		};
	} else {
		return;
	}
	const resp = await axios.post('/api/v1/password-schedule', data);
	if (resp.status !== 200) {
		throw new Error(`Unexpected status code: ${resp.status}`);
	}
	// if (passwordConfig.passwordGenerationType === 'manual') {
	// 	dispatch(vaultActions.setVaultManualPasswordByPolicy({ policyId, manualPassword: manualPasswordValue }));
	// }
};

const deletePasswordSchedule = ({ policyId }: { policyId: Policy['id'] }): AppThunk => async dispatch => {
	const data = {
		policyId
	};

	const resp = await axios.delete('/api/v1/password-schedule', { data });
	if (resp.status !== 200) {
		throw new Error(`Unexpected status code: ${resp.status}`);
	}
	// dispatch(vaultActions.setVaultManualPasswordByPolicy({ policyId, manualPassword: undefined }));
};

export const createPolicy = ({
	policyName,
	policyItems,
	pingInterval,
	telemetryCheckInterval,
	offlineThreshold,
	passwordConfig,
	manualPasswordValue
}: {
	policyName: string;
	policyItems: {
		[policyItemKey: string]: PolicyItemSetting;
	};
	pingInterval: Policy['pingInterval'];
	telemetryCheckInterval: Policy['telemetryCheckInterval'];
	offlineThreshold: Policy['offlineThreshold'];
	passwordConfig?: PasswordConfig;
	manualPasswordValue?: string;
}): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);
	const userVault = getUserVault(state);
	const permissionCheck = getPermissionCheck(state);
	const licenseGroupData = getSelectedLicenseGroupData(getState());

	let secrets;
	if (passwordConfig?.passwordGenerationType === 'manual' && !_.isEmpty(manualPasswordValue)) {
		const groupKeys = userVault?.vault.get(licenseGroupId)?.keys;
		try {
			if (_.isNil(groupKeys)) {
				throw new Error('Missing group entry in vault');
			}
			secrets = Crypto.exportEncrypted(
				await Crypto.encryptObject(
					{
						adminPassword: manualPasswordValue
					},
					groupKeys
				)
			);
		} catch (error) {
			console.error(error);
			dispatch(appActions.alert('failed to create policy'));
		}
	}

	const modifiedPolicyItems = _.mapValues(policyItems, (setting, key) => ({
		...setting,
		// HACK-ish::forcibly set to false if a) the policy item doesn't support remediation or b) the tenant plan doesn't support remediation
		remediation:
			remediatablePolicyItems.includes(key) && permissionCheck('policy_remediation') ? setting.remediation : false
	}));

	const data = {
		tenantId: licenseGroupId,
		tenantUpdatedAt: `${licenseGroupData.tenantUpdatedAt}`,
		policyName,
		policyItems: reverseParseJsonHack(enrichPolicyItemsHack(modifiedPolicyItems)),
		timing: {
			pingInterval,
			telemetryCheckInterval,
			offlineThreshold
		},
		...(passwordConfig && { passwordConfig }),
		policyTs: reverseParseJsonHack(Date.now())
	};

	if (!_.isNil(secrets)) {
		Object.assign(data, { secrets: Util.Buffer.toBase64(secrets) });
	}

	try {
		// const response = await axios.patch(`/api/v1/policy/${licenseGroupId}`, data);
		const response = await axios.post(`/api/v1/policy`, data);
		// @ts-ignore
		if (responseError(response)) {
			if (response.data.returnCode === 409)
				return dispatch(appActions.alert('failed to update data for this tenant - please refresh', 'warning'));
			dispatch(appActions.alert('failed to create policy', 'warning'));
		} else {
			try {
				const policyId = response.data?.policyId;
				if (!_.isString(policyId) || _.isEmpty(policyId)) {
					throw new Error('Missing policy ID');
				}
				if (passwordConfig?.passwordGenerationType !== undefined) {
					await dispatch(submitPasswordChange({ policyId, passwordConfig, manualPasswordValue }));
				}
				dispatch(appActions.alert('policy created', 'success'));
			} catch (error) {
				console.error(error);
				dispatch(appActions.alert('failed to update password configuration', 'warning'));
			}
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editPolicy = ({
	policyId,
	policyName,
	policyItems,
	pingInterval,
	telemetryCheckInterval,
	offlineThreshold,
	passwordConfig,
	manualPasswordValue
}: {
	policyId: Policy['id'];
	policyName?: string;
	policyItems?: {
		[policyItemKey: string]: PolicyItemSetting;
	};
	pingInterval?: Policy['pingInterval'];
	telemetryCheckInterval?: Policy['telemetryCheckInterval'];
	offlineThreshold?: Policy['offlineThreshold'];
	passwordConfig?: PasswordConfig;
	manualPasswordValue?: string;
}): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);
	const userVault = getUserVault(state);
	const permissionCheck = getPermissionCheck(state);
	const prevPasswordGenerationType = getPasswordConfigByPolicyId(policyId)(state).passwordGenerationType;
	const licenseGroupData = getSelectedLicenseGroupData(getState());

	let secrets;
	if (passwordConfig?.passwordGenerationType === 'manual' && !_.isEmpty(manualPasswordValue)) {
		const groupKeys = userVault?.vault.get(licenseGroupId)?.keys;
		try {
			if (_.isNil(groupKeys)) {
				throw new Error('Missing group entry in vault');
			}
			secrets = Crypto.exportEncrypted(
				await Crypto.encryptObject(
					{
						adminPassword: manualPasswordValue
					},
					groupKeys
				)
			);
		} catch (error) {
			console.error(error);
			dispatch(appActions.alert('failed to create policy'));
		}
	}

	const modifiedPolicyItems =
		policyItems === undefined
			? undefined
			: _.mapValues(policyItems, (setting, key) => ({
					...setting,
					// HACK-ish::forcibly set to false if a) the policy item doesn't support remediation or b) the tenant plan doesn't support remediation
					remediation:
						remediatablePolicyItems.includes(key) && permissionCheck('policy_remediation')
							? setting.remediation
							: false
			  }));

	const data = {
		policyName,
		tenantUpdatedAt: `${licenseGroupData.tenantUpdatedAt}`,
		policyItems: reverseParseJsonHack(enrichPolicyItemsHack(modifiedPolicyItems)),
		...(pingInterval &&
			telemetryCheckInterval &&
			offlineThreshold && {
				timing: {
					pingInterval,
					telemetryCheckInterval,
					offlineThreshold
				}
			}),
		...(passwordConfig && { passwordConfig }),
		policyTs: reverseParseJsonHack(Date.now())
	};

	if (!_.isNil(secrets)) {
		Object.assign(data, { secrets: Util.Buffer.toBase64(secrets) });
	}

	try {
		const response = await axios.patch(`/api/v1/policy/${licenseGroupId}/${policyId}`, data);
		// @ts-ignore
		if (responseError(response)) {
			if (response.data.returnCode === 409)
				return dispatch(appActions.alert('failed to update data for this tenant - please refresh', 'warning'));
			dispatch(appActions.alert('failed to update policy', 'warning'));
		} else {
			try {
				if (passwordConfig) {
					if (passwordConfig.passwordGenerationType !== undefined) {
						await dispatch(submitPasswordChange({ policyId, passwordConfig, manualPasswordValue }));
					} else if (prevPasswordGenerationType === 'random') {
						await dispatch(deletePasswordSchedule({ policyId }));
					}
				}
				dispatch(appActions.alert('policy updated', 'success'));
			} catch (error) {
				console.error(error);
				dispatch(appActions.alert('failed to update password configuration', 'warning'));
			}
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const deletePolicies = (policyIds: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	// CHANGEME::TODO::unassign all policies to devices & deviceGroups first in UI
	const devicesWithThisPolicy = getDevices(getState())
		.filter(({ policyId }) => policyId && policyIds.includes(policyId))
		.map(({ serial }) => serial);

	try {
		if (devicesWithThisPolicy.length) {
			await dispatch(devicesActions.assignPolicyToDevices(devicesWithThisPolicy, undefined));
		}
		// const responses = await Promise.all(
		// 	_.chunk(policyIds, 25).map(chunk =>
		// 		axios.delete(`/api/v1/policy/${licenseGroupId}`, {
		// 			params: { policyId: chunk },
		// 			paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
		// 		})
		// 	)
		// );
		// TEMP::missing bulk delete so looping per action
		const responses = await Promise.all(
			policyIds.map(policyId => axios.delete(`/api/v1/policy/${licenseGroupId}/${policyId}`))
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to delete some policies', 'warning'));
		} else {
			dispatch(appActions.alert('policies deleted', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
